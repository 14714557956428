import React, { Component } from "react";
import { Link } from "react-router-dom";
import firestore from "../../../../Config/Config";
import { doc, getDoc } from "firebase/firestore";
import NoData from "../../../NoData";
import Loading from '../../../Loading';

export class NursingFaculties extends Component {

  state = { Datas: [] }

  //Load Data Here
  async componentDidMount() {
    //For Teaching
    const docRef = doc(firestore, "FundamentalNursing", "Faculties");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });
      console.log(Data.sort(function (a, b) { return a.Priority - b.Priority; }));
      Data.length === 0 ?
        this.setState({ Datas: "NO_999" }) :
        this.setState({ Datas: Data })
    } else {
      this.setState({
        Datas: "NO_999"
      })
    }
  }

  render() {
    return (
      <div className="site-main">
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-12 content-area order-lg-2">
                <section className=" grid-section clearfix">
                  <div className="container-xl">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header">
                        <h2 className="title">Teaching <span> Staff</span></h2>
                      </div>
                    </div>
                    <div className="row">
                      {
                        this.state.Datas.length === 0 ? <Loading /> :
                          this.state.Datas === "NO_999" ? <NoData /> :
                            this.state.Datas.map((item, index) => (
                              <div className="col-md-6 col-lg-4 col-sm-6" key={index}>
                                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                                  <div className="featured-thumbnail">
                                    <img className="img-fluid d-flex image_style" src={item.Image} alt="image" />
                                  </div>
                                  <div className="featured-content box-shadow">
                                    <div className="featured-title">
                                      <h5><Link to={{ pathname: process.env.PUBLIC_URL + `/EachFaculty/${item.Id}/FundamentalNursing`, state: { facData: item } }}>{item.Name}</Link></h5>
                                    </div>
                                    <p className="category">{item.Designation}</p>
                                  </div>
                                </div>
                              </div>
                            ))
                      }
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NursingFaculties;
