import React, { Component } from "react";
import Header from "../layout/Header";
import firestore from "../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import NoData from "../NoData";

export class Achievers extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Academics: [],
      Culture: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "Achievers");
    const academicskey = "Academics";
    const culturekey = "Culture";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      const allDynamicData = this.getDynamicData(Snapshot);
      this.dynamicData.Academics = allDynamicData[academicskey];
      this.dynamicData.Culture = allDynamicData[culturekey];
    }
    this.setState({});
  }
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row achievers_banner ">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Achievers KIN
                    </h4>

                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Achievers</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="ttm-row grid-section ttm-bgcolor-grey clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Academic <span>Achievers</span>
                    </h2>
                  </div>
                </div>
              </div>
              {this.dynamicData.Academics === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.Academics.length &&
                this.dynamicData.Academics.map(function (item, index) {
                  return (
                    <div
                      className="col-lg-6 col-sm-12 col-md-6 col-xs-12"
                      key={index}
                    >
                      <div className="row no-gutters featured-imagebox featured-imagebox-post ttm-box-view-left-image box-shadow">
                        <div className="col-lg-5 col-md-12 col-sm-6 ttm-featured-img-left">
                          <div className="featured-thumbnail">
                            <img
                              className="img-fluid"
                              src={item.Image}
                              alt="img"
                              style={{ width: "218px", height: "291px" }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-6 featured-content">
                          <div className="post-title featured-title">
                            <h5>{item.Name}</h5>
                            <h3>{item.Header}</h3>
                          </div>
                          <div
                            className="featured-desc ttm-box-desc"
                            style={{ paddingTop: "0px", marginTop: "2px" }}
                          >
                            <p>{item.Description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </section>

        <section className="ttm-row grid-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Achievers <span>Sports</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {this.dynamicData.Culture === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.Culture.length &&
                this.dynamicData.Culture.map(function (item, index) {
                  return (
                    <div className="col-md-6 col-lg-4" key={index}>
                      <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={item.Image}
                            alt="image"
                            style={{ width: "360px", height: "270px" }}
                          />
                        </div>
                        <div className="featured-content box-shadow">
                          <div className="featured-title">
                            <h5>{item.Name}</h5>
                          </div>
                          <div className="featured-desc">
                            <p>{item.Description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Achievers;
