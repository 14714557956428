import React, { Component } from "react";
import FacilitiesSidebar from "./FacilitiesSidebar";
import Header from "../layout/Header";
import image from "../../images/facility/library/image.JPG";
import Slider from "react-slick";
import image2 from "../../images/facility/library/image2.JPG";
import image3 from "../../images/facility/library/image3.JPG";
import image4 from "../../images/facility/library/image4.JPG";
import image5 from "../../images/facility/library/image5.JPG";

export class Facilities extends Component {
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row facilities_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h2 className="title" style={{ color: "white" }}>Facilities</h2>
                    <div className="ttm-color-home">
                      <span><a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}><i className="ti ti-home" />&nbsp;&nbsp;Home</a></span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">KIN Facilities</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="ttm-service-description">
                    <div className="section-title with-desc clearfix">
                      <div className="title-header"><h2 className="title">Library</h2></div>
                    </div>
                    <Slider className="slick_slider project-single" {...slick_slider} arrows={false} dots={true}>
                      <div className="featured-thumbnail"><img src={image} alt="image" className="img-fluid" /></div>
                      <div className="featured-thumbnail"><img src={image2} alt="image" className="img-fluid" /></div>
                      <div className="featured-thumbnail"><img src={image3} alt="image" className="img-fluid" /></div>
                      <div className="featured-thumbnail"><img src={image4} alt="image" className="img-fluid" /></div>
                      <div className="featured-thumbnail"><img src={image5} alt="image" className="img-fluid" /></div>
                    </Slider>
                    <br />
                    <p className="text-justify">
                      KIN has a well-maintained comprehensive library to fulfill latest information needs of nursing students. KIN library has a vast collection (more than 3000 titles ) of text
                      books and reference books which helps students to have in-depth understanding of any required subject. KIN
                      library has subscribed many National and InterNational journals which provide information on latest developments in the relevant fields of nursing.
                    </p>
                    <p className="text-justify">
                      <b>KIN Digital Library - </b> KIN has well connected digital library having computers with internet connectivity. Many National and InterNational e –
                      journals and e – books are available for reference in digital library. For e – journals, KIN digital library has access to HELINET consortium from Rajiv Gandhi University of Health Sciences.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <FacilitiesSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Facilities;
