import React, { Component } from "react";
import firestore from "../../../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import NoData from "../../../NoData";

export class CommunityResearch extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Publication: [],
      ResearchGrants: [],
      ResearchGuides: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "CommunityHealth");
    const publicationkey = "Publication";
    const resguidekey = "ResearchGuides";
    const resgrantskey = "ResearchGrants";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      const allDynamicData = this.getDynamicData(Snapshot);
      this.dynamicData.Publication = allDynamicData[publicationkey];
      this.dynamicData.ResearchGuides = allDynamicData[resguidekey];
      this.dynamicData.ResearchGrants = allDynamicData[resgrantskey];
    }
    this.setState({});
  }
  render() {
    return (
      <div className="site-main">
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-12 content-area order-lg-2">
                <section className=" history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix" style={{ paddingBlockEnd: "30px" }}>
                  <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                  <div className="container-xl">
                    <div className="row">
                      <div className="col-lg-8 offset-lg-2">
                        <div className="section-title with-desc text-center clearfix">
                          <div className="title-header">
                            <h2 className="title">Research <span>Guides </span></h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row no-gutters">
                      {this.dynamicData.ResearchGuides === undefined ? ( <NoData />) : (
                        this.dynamicData.ResearchGuides.length &&
                        this.dynamicData.ResearchGuides.map(function (item, index) {
                          return (
                            <div className="col-md-4 " key={index} style={{ padding: "5px" }}>
                              <div className="featured-icon-box style6">
                                <div className="featured-content">
                                  <div className="featured-title">
                                    <h5> {item.Name}</h5>
                                  </div>
                                  <div className="featured-desc">
                                    <p>
                                      <i
                                        class="fab fa-searchengin"
                                        style={{
                                          color: "#black",
                                        }}
                                      ></i>{" "}
                                      Research: {item.Research}
                                    </p>
                                  </div>
                                  <div className="featured-desc">
                                    <p>
                                      <i
                                        class="fas fa-user-graduate"
                                        style={{
                                          color: "#black",
                                        }}
                                      />{" "}
                                      Students Pursuing: {item.Student}
                                    </p>
                                  </div>
                                  <div className="featured-desc">
                                    <p>
                                      <i
                                        class="fas fa-users"
                                        style={{
                                          color: "#black",
                                        }}
                                      />{" "}
                                      Vacancy : {item.Vacancy}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <section className=" price-text-section res-991-pb-0  clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-md-12">
                    <div className="section-title text-center with-desc clearfix">
                      <div className="title-header">
                        <h2 className="title">
                          Research <span>Grants</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="ttm-row topzero-padding-section res-991-pt-0 clearfix">
              <div className="container-xl">
                <div className="row no-gutters mt_100 res-991-mt-0">
                  {this.dynamicData.ResearchGrants === undefined ? (
                    <NoData />
                  ) : (
                    this.dynamicData.ResearchGrants.length &&
                    this.dynamicData.ResearchGrants.map(function (item, index) {
                      return (
                        <div className="col-md-4" key={index}>
                          <div
                            className="ttm-pricing-plan box-shadow text-left clearfix "
                            style={{
                              marginRight: "20px",
                              marginBlockEnd: "20px",
                            }}
                          >
                            <div className="ttm-ptablebox-title">
                              <h3>{item.Title}</h3>
                            </div>
                            <div className="ttm-ptablebox-desc">
                              {item.Name}
                            </div>
                            <div
                              className="ttm-ptablebox-content"
                              style={{ marginBlockStart: "20px" }}
                            >
                              <div className="ttm-ptablebox-price-w">
                                <div className="ttm-ptablebox-cur-symbol-before">
                                  <i class="fas fa-rupee-sign"></i>
                                </div>
                                <div
                                  className="ttm-ptablebox-price"
                                  style={{ fontSize: "30px" }}
                                >
                                  {item.Amount}
                                </div>
                              </div>
                              <div className="ttm-ptablebox-features">
                                <ul className="ttm-feature-lines">
                                  <li>Duration : {item.Duration}</li>
                                  <li>Investor : {item.Investor}</li>
                                  <li>Status : {item.Status}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </section>

            <section className="aboutus-section clearfix">
              <div className=" only-one-section ttm-bgcolor-white clearfix">
                <div className="container-xl">
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">Research <span>Publication</span></h2>
                    </div>
                  </div>
                  <section className="ttm-row bottomzero-padding-section  ttm-bg ttm-bgimage-yes bg-img5 ttm-bgcolor-skincolor clearfix">
                    <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                    <div className="container-xl pb-5 res-991-pb-0">
                      <div className="row align-items-center ttm-textcolor-white">
                        <div className="col-lg-8 res-991-text-center">
                        <h5 className="title-desc">A repository of research publications of teaching faculty and post graduate scholars related to subject specialty illuminating on innovative practices and research concepts</h5>
                        </div>
                        <div className="col-lg-4 text-right res-991-text-center res-991-mt-20">
                          <a href="true" className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white">Publications</a>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default CommunityResearch;
