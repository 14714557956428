import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBRow, } from "mdbreact";
import image1 from "../../images/Home/bannerimg01.jpg";
import image2 from "../../images/Home/bannerimg02.jpg";
import image3 from "../../images/Home/bannerimg03.jpg";
import image4 from "../../images/Home/bannerimg04.jpg";

export class CarouselPage extends Component {
  render() {
    return (
      <MDBContainer fluid>
        <MDBRow>
          <MDBCarousel activeItem={1} length={4} showControls={true} showIndicators={false} id="Home_banner">
            <MDBCarouselInner>
              <MDBCarouselItem itemId="1">
                <MDBView><img className="d-block w-100" src={image1} alt="First slide" /></MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="2">
                <MDBView><img className="d-block w-100" src={image2} alt="Second slide" /></MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl">
                    <div className="carousel-caption-inner banner2">
                      <MDBAnimation className="slideInUp" count={1} delay="3s">
                        <h2 style={{ marginLeft: "100px", color: "white" }}>Vibrant</h2>
                        <h3 style={{ marginLeft: "100px", color: "#009c9f" }}><strong>Academic Environment</strong></h3>
                        <h3 style={{ marginLeft: "100px", color: "white" }}>Nurture,Friendly & Supportive Environment</h3>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="3">
                <MDBView><img className="d-block w-100" src={image3} alt="Second slide" /></MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl">
                    <div className="carousel-caption-inner banner2">
                      <MDBAnimation className="slideInUp" count={1} delay="3s">
                        <h2 style={{ color: "white" }}>30 years of</h2>
                        <h3 style={{ color: "#009c9f" }}><strong>Excellence</strong></h3>
                        <h6 style={{ color: "white" }}>We are equipped with an updated technical knowledge to serve our customers<br /> Properly. Our method of application maintains the Industry.</h6>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="4">
                <MDBView><img className="d-block w-100" src={image4} alt="Second slide" /></MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl">
                    <div className="carousel-caption-inner banner2">
                      <MDBAnimation className="slideInUp" count={1} delay="3s">
                        <h2
                          className="ttm-textcolor-darkgrey" style={{ marginLeft: "100px",color:"white" }}>Qualified & Experienced<br/>
                          <strong className="ttm-textcolor-skincolor" style={{ color:"white"}}>Faculty</strong>
                        </h2>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
            </MDBCarouselInner>
          </MDBCarousel>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default CarouselPage;
