import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCJ7Q2VAO2whSlRkezuSfc0wCqzEHM2Ei4",
  authDomain: "rvs-nursing.firebaseapp.com",
  projectId: "rvs-nursing",
  storageBucket: "rvs-nursing.appspot.com",
  appId: "1:492194167321:web:69d6740ea86c7db75a0888",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export default firestore;
