import React, { Component } from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";
import prospectus from "../../assets/Admission/Collegebroucher.pdf";

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer widget-footer clearfix">
        {this.props.children}
        <div className={`first-footer ttm-bgcolor-aqua ttm-bg ttm-bgimage-yes prospectusBacImg ${styles.prospectusBacImg}`}>
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row align-items-md-center">
              <div className="col-lg-4 col-md-4 col-sm-12 order-md-2"></div>
              <div className="col-lg-4 col-md-4 col-sm-6 order-md-1 res-767-mt-20">
                <div className="text-left">
                  <div className="featured-icon-box left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                        <i className="ti ti-location-pin" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-desc">
                        <p className="header">Kempegowda Institute of Nursing</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 order-md-3 res-767-mt-20">
                <div className="text-sm-right">
                  <a href={prospectus} target="newwindow" rel="noopener noreferrer" className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white">
                    <i className="far fa-envelope" /> Prospectus
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="second-footer ttm-textcolor-white footer-img-one">
          <div className="container-xl">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 widget-area">
                <div className="widget widget_text  clearfix">
                  <h3 className="widget-title">About Our Institution</h3>
                  <div className="textwidget widget-text text-justify">
                    Kempegowda Institute of Nursing (KIN) is a leading institute
                    offering quality nursing education established in 1990 and
                    is one of the most hunted Institutions globally for
                    undergraduate and postgraduate admissions which offer state
                    of the art clinical and community training facilities. The
                    teaching hospital is the core of excellent healthcare
                    services for the patients and a renowned centre for learning
                    nursing.
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget link-widget clearfix">
                  <h3 className="widget-title">Quick Links</h3>
                  <ul id="menu-footer-services">
                    <li><a href="http://rvsangha.org/"> Rajya Vokkaligara Sangha</a></li>
                    <li><Link to={process.env.PUBLIC_URL + "/committees"}>Committee</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + "/department"}>Department</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + "/admission"}>Admission</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + "/facilities"}>Facilities</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget widget_text clearfix">
                  <h3 className="widget-title">For Support</h3>
                  <p>KEMPEGOWDA INSTITUTE OF NURSING</p>
                  <p>KR Road, VV Puram</p>
                  <p>BANGALORE- 560004</p>
                  <p>+91 9448809024</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer-text ttm-bgcolor-yellow ttm-textcolor-white">
          <div className="container-xl">
            <div className="row copyright align-items-center res-767-text-center">
              <div className="col-md-6">
                <div>
                  <span>Copyright © 2021&nbsp;<a to="/">KIN</a> by
                    <a href="http://shoonyeka.com/#/" target="_blank"> Shoonyeka Solutions , Mysuru.</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
