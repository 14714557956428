import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import pdf from "../../assets/Recognition-KIN.pdf";
import pdf1 from "../../assets/Affiliation-KIN.pdf";
import pdf2 from "../../assets/c and r rules.pdf";

export class FacilitiesSidebar extends Component {
  render() {
    return (
      <div>
        <div className="widget widget-nav-menu">
          <ul className="widget-menu">
            <li><NavLink to={process.env.PUBLIC_URL + "/facilities"}>Library</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/Skill"}>Skill training departmental lab</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/classroom"}>Class Rooms</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/clinical"}>Clinical Facilities</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/community"}>Community Facilities</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/transport"}>Transport</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/hostel"}>Hostel</NavLink></li>
          </ul>
        </div>
        <div className="widget widget-download">
          <h3 className="widget-title">Rules and Regulations</h3>
          <ul className="download">
            <li><i className="far fa-file-pdf" /><a className="ttm-btn btn-inline style2" href={pdf1} target="_blank" rel="noopener noreferrer" download>KIN Service Rules</a></li>
            <li><i className="far fa-file-pdf" /><a className="ttm-btn btn-inline style2" href={pdf2} target="_blank" rel="noopener noreferrer" download>C and R Rules</a></li>
          </ul>
        </div>
        <div className="widget widget-download">
          <h3 className="widget-title">Affiliations</h3>
          <ul className="download">
            <li><i className="far fa-file-pdf" /><a className="ttm-btn btn-inline style2" href={pdf1} target="_blank" rel="noopener noreferrer" download>Affiliation KIN</a></li>
            <li><i className="far fa-file-pdf" /><a className="ttm-btn btn-inline style2" href={pdf} target="_blank" rel="noopener noreferrer" download>Recognition KIN</a></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default FacilitiesSidebar;
