import React, { Component } from "react";
import FacilitiesSidebar from "./FacilitiesSidebar";
import Header from "../layout/Header";
import Slider from "react-slick";
import image1 from "../../images/facility/classroom/image1.JPG";
import image2 from "../../images/facility/classroom/image2.JPG";
import image3 from "../../images/facility/classroom/image3.JPG";
import image4 from "../../images/facility/classroom/image4.JPG";
import image5 from "../../images/facility/classroom/image5.JPG";
import image6 from "../../images/facility/classroom/image6.JPG";

export class ClassRoom extends Component {
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row facilities_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h2 className="title" style={{ color: "white" }}>Facilities</h2>
                    <div className="ttm-color-home">
                      <span><a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}><i className="ti ti-home" />&nbsp;&nbsp;Home</a></span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">KIN Facilities</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title text-left with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">Class <span>Rooms</span></h2>
                  </div>
                </div>
                <Slider className="slick_slider project-single" {...slick_slider} arrows={false} dots={true}>
                  <div className="featured-thumbnail"><img src={image1} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image2} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image3} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image4} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image5} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image6} alt="image" className="img-fluid" /></div>
                </Slider>
                <br/>
                <p className="text-justify">
                  Our institute has created a safe learning environment
                  with state of art facilities to ensure holistic
                  development of our students. The classrooms are adequate
                  in number; well ventilated, spacious, classrooms with
                  comfortable seating arrangements equipped with
                  traditional & modern teaching aids like Black board, LCD
                  projectors and Over head projector are available. High
                  tech classrooms help us to provide a perfect brew of
                  classroom and online classes.
                </p>
              </div>
              <div className="col-lg-3 widget-area">
                <FacilitiesSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClassRoom;
