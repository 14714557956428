import React, { Component } from "react";
import Header from "../../layout/Header";
import firestore from "../../../Config/Config";
import { doc, onSnapshot } from "firebase/firestore";
import NoData from "../../NoData";

export class EachFaculties extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Faculties: [],
    };

    this.state = {
      Faculties: [],
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    this.state.Faculties = this.props.match.params.id;
    onSnapshot(
      doc(firestore, this.props.match.params.deptname, "Faculties"),
      (doc) => {
        if (doc.exists()) {
          Object.entries(doc.data()).forEach(([key, value]) => {
            this.dynamicData.Faculties.push(Object.assign({ Id: key }, value));
          });
          this.setState({
            Faculties: this.GetFacultyById(this.dynamicData.Faculties),
          });
        } else {
        }
      }
    );
  }
  GetFacultyById(param1) {
    let FacDetail = param1.filter((a) => {
      return a.Id === this.props.match.params.id;
    });
    return FacDetail;
  }
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row deptpage_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>Faculty Details</h4>
                    <div className="ttm-color-home">
                      <span><a className="ttm-color-home" title="Homepage" href="/"><i className="ti ti-home" />&nbsp;&nbsp;Home</a></span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Our Staff</span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span> Teaching Learning</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="ttm-row team-details-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="row">
                {this.state.Faculties === undefined ? (
                  <NoData />
                ) : (
                  this.state.Faculties.length &&
                  this.state.Faculties.map(function (item, index) {
                    return (
                      <div className="col-lg-4">
                        <div className="ttm-featured-wrapper" key={index}>
                          <div className="featured-thumbnail text-center">
                            <img
                              className="img-fluid"
                              src={item.Image}
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
                {this.state.Faculties === undefined ? (
                  <NoData />
                ) : (
                  this.state.Faculties.length &&
                  this.state.Faculties.map(function (item, index) {
                    return (
                      <div className="col-lg-8" Key={index}>
                        <div className="ttm-team-member-content shadow-box res-991-mt-30">
                          <div className="ttm-team-member-single-list">
                            <h2 className="ttm-team-member-single-title">{item.Name}</h2>
                            <span className="ttm-team-member-single-position">{item.Designation}</span>
                            <p><b>Qualification : </b>{item.Qualification}</p>
                            <p><b>Additional Qualification : </b>{item.AdditionalQualification}</p>
                            <p><b>Teaching Interest : </b>{item.TeachingInterest}</p>
                            <p><b>Research Interest : </b>{item.ResearchInterest}</p>
                            <div className="separator"><div className="sep-line mt-4 mb-4" /></div>
                            <div className="ttm-team-data">
                              <div className="ttm-team-details-wrapper">
                                <ul className="ttm-team-details-list clearfix">
                                  <li>
                                    <div className="ttm-team-list-title"><i className="ti ti-book" /> No of Publication : </div>
                                    <div className="ttm-team-list-value">{item.Publications}</div>
                                  </li>
                                  <li>
                                    <div className="ttm-team-list-title"><i class="fas fa-business-time"></i> Experience : </div>
                                    <div className="ttm-team-list-value">{item.Experience}</div>
                                  </li>
                                  <li>
                                    <div className="ttm-team-list-title"><i class="fas fa-newspaper" /> No of Paper Presentation : </div>
                                    <div className="ttm-team-list-value">{item.PaperPresent}</div>
                                  </li>
                                  <li>
                                    <div className="ttm-team-list-title"><i className="ti ti-notepad" /> Workshop Conducted : </div>
                                    <div className="ttm-team-list-value">{item.ConductedWorkshop}</div>
                                  </li>
                                  <li>
                                    <div className="ttm-team-list-title"><i className="ti ti-pencil-alt" /> Workshop Attended :</div>
                                    <div className="ttm-team-list-value">{item.AttendedWorkshop}</div>
                                  </li>
                                  <li>
                                    <div className="ttm-team-list-title"><i className="ti ti-user" /> Resource Person : </div>
                                    <div className="ttm-team-list-value">{item.ResourcePerson}</div>
                                  </li>
                                  <li>
                                    <div className="ttm-team-list-title"><i className="ti ti-email" /> Email : </div>
                                    <div className="ttm-team-list-value"><a href="mailto:info@example.com">{item.EmailId}</a></div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="separator">
                              <div className="sep-line mt-3 mb-4" />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default EachFaculties;
