import React, { Component } from "react";
import Header from "../../layout/Header";
import image1 from "../../../images/Department/CommunityHealth.jpg";
import image2 from "../../../images/Department/medicalsurgical.jpg";
import image3 from "../../../images/Department/gynocol.jpg";
import image4 from "../../../images/Department/childnursing.jpg";
import image5 from "../../../images/Department/mental.jpg";
import image6 from "../../../images/Department/fundamentals.jpg";
import { Link } from "react-router-dom";

export class Department extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row deptpage_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading  card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Department
                    </h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">Department</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="ttm-row grid-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    <img
                      className="dept-image"
                      src={image1}
                      alt="First slide"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      <h5>
                        <Link to={process.env.PUBLIC_URL + "/communitydept"}>
                          Community Health Nursing
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    <img
                      className="dept-image"
                      src={image2}
                      alt="First slide"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      <h5>
                        <Link
                          to={process.env.PUBLIC_URL + "/MedicalSugicaldept"}
                        >
                          Medical Surgical Nursing
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    <img className="dept-image" src={image3} alt="image" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      <h5>
                        <Link
                          to={process.env.PUBLIC_URL + "/ObstetricsGynaeDept"}
                        >
                          Obstetrics & Gynaecological Nursing
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    <img className="dept-image" src={image4} alt="image" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      <h5>
                        <Link to={process.env.PUBLIC_URL + "/ChildHealthdept"}>
                          Child Health Nursing
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    <img className="dept-image" src={image5} alt="image" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      <h5>
                        <Link to={process.env.PUBLIC_URL + "/MentalHealthdept"}>
                          Mental Health Nursing
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    <img className="dept-image" src={image6} alt="image" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      <h5>
                        <Link to={process.env.PUBLIC_URL + "/NursingFnDept"}>
                          Fundamentals of Nursing
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Department;
