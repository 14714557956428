import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import Header from "../layout/Header";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import firestore from "../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import NoData from "../NoData";

const images = [
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
];

export class Gallery extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Gallery: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "Gallery");
    const GalleryKey = "Gallery";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      const allDynamicData = this.getDynamicData(Snapshot);
      this.dynamicData.Gallery = allDynamicData[GalleryKey];
    }
    const arr = [];
    this.setState({ Gallery: arr });
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row gallery-banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Gallery{" "}
                    </h4>
                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>KIN Gallery</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
        <div className="ttm-row tab-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-12">
                <div className="ttm-tabs text-center ttm-tab-style-classic style2">
                  <div className="row multi-columns-row ttm-boxes-spacing-5px">
                    {this.dynamicData.Gallery === undefined ? (
                      <NoData />
                    ) : (
                      this.dynamicData.Gallery.length &&
                      this.dynamicData.Gallery.map(function (item, index) {
                        return (
                          <div
                            className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6"
                            key={index}
                          >
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                              <div className="featured-thumbnail">
                                <img
                                  className="img-fluid"
                                  src={item.Image}
                                  alt="image"
                                  style={{ width: "360px", height: "330px" }}
                                />
                              </div>
                              <div className="featured-content">
                                <div className="category">
                                  <p> {item.Description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
