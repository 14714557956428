import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../layout/Header";
import image from "../../images/admission_process.JPG";
import firestore from "../../Config/Config";
import { doc, getDoc } from "firebase/firestore";
import pdf from "../../assets/Admission/NPCCApplicationForm.pdf";
import prospectus from "../../assets/Admission/Collegebroucher.pdf";
import NoData from "../NoData";
import Loading from '../Loading';

export class Npcc extends Component {

  state = { Datas: [] }

  //Load Data Here
  async componentDidMount() {
    //For Teaching
    const docRef = doc(firestore, "Admission", "NpccFee");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });
      console.log(Data.sort(function (a, b) { return a.Priority - b.Priority; }));
      Data.length === 0 ?
        this.setState({ Datas: "NO_999" }) :
        this.setState({ Datas: Data })
    } else {
      this.setState({
        Datas: "NO_999"
      })
    }
  }

  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row admission_banner ">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>Admission</h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">Admission</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="ttm-row aboutus-section clearfix ">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Admission for <span> NPCC</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      The NPCC Nursing is a two-year program under RGUHS.
                      Advanced knowledge and skill is rendered through
                      classroom, clinical practice and educational visits. The
                      scope encompasses nursing educational institutions, and
                      clinical nursing. The University examinations are held
                      annually…
                    </p>
                  </div>
                </div>
                <a
                  className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white ttm-bgcolor-aqua"
                  href={prospectus}
                  target="newWindow"
                  style={{ backgroundColor: "#009c9f" }}
                >
                  KIN Prospectus
                </a>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image}
                      alt="single-img-seven"
                      style={{ width: "500px", height: "400px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row aboutus-section clearfix zero-padding-section">
          <div className="sidebar ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-lg-12 content-area">
                  <div className="ttm-single-product-details product">
                    <div className="ttm-tabs tabs-for-single-products">
                      <Tabs>
                        <TabList>
                          <Tab>
                            <span>Seats Available</span>
                          </Tab>
                          <Tab>
                            <span>Eligibility</span>
                          </Tab>
                          <Tab>
                            <span>Documents to be submitted</span>
                          </Tab>
                          <Tab>
                            <span>Download Application for Admission</span>
                          </Tab>
                        </TabList>
                        <div className="border_content-tab">
                          <TabPanel>
                            <div className="content-inner">
                              <h2>Seats Available</h2>
                              <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor aqua-color" />
                                  Courses offered: NPCC{" "}
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor aqua-color" />
                                  Graduation: Post graduation{" "}
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor aqua-color" />
                                  Duration: 2 years
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor aqua-color" />
                                  Intake per year: Management seat: 05
                                </li>
                              </ul>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <h2>Eligibility</h2>
                              <div className="content-inner">
                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                  <li>
                                    {" "}
                                    1. The candidate should be a Registered
                                    Nurse and Registered midwife or equivalent
                                    with any State Nursing Registration Council.
                                  </li>
                                  <li>
                                    2. The Minimum education requirements shall
                                    be the passing of: B.Sc. Nursing/B.Sc. Hons.
                                    Nursing/Post Basic B.Sc. Nursing with
                                    minimum of 55% aggregate marks.{" "}
                                  </li>
                                  <li>
                                    3. Minimum one year of clinical experience
                                    preferably ICU/CCU/EMERGENCY WARD after
                                    Basic B.Sc. Nursing. Note: The special
                                    feature of this program is that it is a
                                    clinic residency program emphasizing a
                                    strong clinical component with 15% of
                                    theoretical instruction and 85% of
                                    practicum. These Nurse Practitioners are
                                    required to practice in critical care units
                                    of tertiary care centers.{" "}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <h2>
                                Documents to be submitted During Admission
                              </h2>
                              <ul>
                                <li>1. Transfer Certificate </li>
                                <li>2. SSLC/X Marks Card </li>
                                <li>
                                  3. Marks cards of qualifying exam passed or
                                  its equivalent{" "}
                                </li>
                                <li>4. Migration certificate </li>
                                <li>5. Cast certificate </li>
                                <li>
                                  6. Character &conduct certificate from Head of
                                  Previous intuition .
                                </li>
                                <li>
                                  7. Three copies of recent passport size
                                  photograph{" "}
                                </li>
                                <li>8. Attempt certificate.</li>
                                <li>
                                  9. Eligibility certificate from Rajiv Gandhi
                                  University of Health Sciences.{" "}
                                </li>
                                <li>
                                  10. 3set Xerox copy of all the above
                                  certificate.
                                </li>
                              </ul>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <h6>
                                <a href={pdf} download>
                                  Click Here to Download Application Form{" "}
                                </a>
                              </h6>
                            </div>
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className=" aboutus-section clearfix">
          <div className=" only-one-section ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h2 className="title">Fee <span> Structure</span></h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <form className="ttm-cart-form">
                    <table className="shop_table shop_table_responsive">
                      <thead className="th_color" style={{ fontSize: "1rem", textAlign: "center" }}>
                        <tr>
                          <th>Course</th>
                          <th>Year</th>
                          <th>Tution fee - MIS</th>
                          <th>Tution fee - OTIS</th>
                          <th>Stipend</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.Datas.length === 0 ? <Loading /> :
                            this.state.Datas === "NO_999" ? <NoData /> :
                              this.state.Datas.map((item, index) => (
                                <tr className={`cart_item tb-content ${index % 2 == 0 ? " table-bgcolor_blue" : "table-bgcolor_white"}`} key={index} style={{ fontSize: "1rem", textAlign: "center" }}>
                                  <td><h6>{item.Course}</h6></td>
                                  <td><h6>{item.Year}</h6></td>
                                  <td><h6>{item.TutionMIS}</h6></td>
                                  <td><h6>{item.TutionOTIS}</h6></td>
                                  <td><h6>{item.Stipend}</h6></td>
                                </tr>
                              ))
                        }
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Npcc;
