import React, { Component } from "react";
import Header from "../layout/Header";
import image from "../../images/image12.JPG";

export default class ChairmanKIN extends Component {

  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row principalbanner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>Chairman</h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a title="Homepage" href={process.env.PUBLIC_URL + "/"} className="ttm-color-home"><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">KIN</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix" style={{ paddingBlockEnd: "40px" }}>
                  <div className="title-header"><h2 className="title">Chairman<span> Message</span></h2></div>
                  <div className="title-desc-ff">
                    <p className="text-justify">I feel pleasure in extending you a very warm welcome on behalf of the Rajya  vokkaligara sangha and Kempegowda institute of nursing family to the new aspirants seeking admission in this institute, for structuring their career in Nursing. We have clearly laid down our vision, mission and values and efforts shall be made to utilize the infrastructural facilities and intellectual capital to achieve these goals and values. It shall be our endeavor to prepare nurses with a sound educational programme so that they may serve the institutions, patients with extra care and meet the basic health needs of the community.</p>
                    <p className="text-justify">We are conscious of the changing needs of society, hence, we shall emphasize on imparting education and training to the students beyond the prescribed syllabus to make them good human beings & professionals also. The curriculum will be flexible enough to keep pace with changes that take place from time to time in the field of nursing and patient care.</p>
                    <p className="text-justify">We live and work together as a family unit and hope that your stay with us will be more comfortable, fruitful and memorable.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <div className="featured-imagebox featured-imagebox-portfolio ">
                      <div className="featured-thumbnail">
                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/chairman.jpg"} alt="single-img-seven" />
                      </div>
                      <br />
                      <div>
                        <div>
                          <div className="member-info-box border" style={{ padding: "10px", textAlign: "left" }}>
                            <div className="timing-box"><h4>Mr. Ashok S D Jayaram</h4></div>
                            <ul className="info-list">
                              <li>BE(IS&E), MBA (USA)</li>
                              <li><span>Director, Rajya vokkaligara sangha</span></li>
                              <li><span>Contact No : +91 98861 97533</span></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
