import React, { Component } from "react";
import FacilitiesSidebar from "./FacilitiesSidebar";
import Header from "../layout/Header";
import Slider from "react-slick";
import image4 from "../../images/facility/community/image4.JPG";
import image5 from "../../images/facility/community/image5.JPG";
import image6 from "../../images/facility/community/image6.JPG";
import image7 from "../../images/facility/community/image7.JPG";
import image8 from "../../images/facility/community/image8.JPG";

export class Community extends Component {

  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row facilities_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h2 className="title" style={{ color: "white" }}>Facilities</h2>
                    <div className="ttm-color-home">
                      <span><a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}><i className="ti ti-home" />&nbsp;&nbsp;Home</a></span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">KIN Facilities</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title text-left with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">Community <span>Facilities</span></h2>
                  </div>
                </div>
                <Slider className="slick_slider project-single" {...slick_slider} arrows={false} dots={true}>
                  <div className="featured-thumbnail"><img src={image4} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image5} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image6} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image7} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image8} alt="image" className="img-fluid" /></div>
                </Slider>
                <br />
                <p className="text-justify">
                  Institution is affiliated to community health centers in Urban and Rural areas enabling the students to enhance their competencies in functioning as primary health care providers. Students conduct various activities like General health screening camps, school health screening camps in urban and rural areas. Students also participate in national health programme like pulse polio immunization programme and MCH programme. Students have also involved and worked as front line warriors during Covid-pandemic.
                </p>
              </div>
              <div className="col-lg-3 widget-area">
                <FacilitiesSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Community;
