import React, { Component } from "react";
import FacilitiesSidebar from "./FacilitiesSidebar";
import Header from "../layout/Header";
import Slider from "react-slick";
import image1 from "../../images/facility/skill training/image1.JPG";
import image2 from "../../images/facility/skill training/image2.JPG";
import image3 from "../../images/facility/skill training/image3.JPG";
import image4 from "../../images/facility/skill training/image4.JPG";
import image5 from "../../images/facility/skill training/image5.JPG";
import image6 from "../../images/facility/skill training/image6.JPG";
import image7 from "../../images/facility/skill training/image7.JPG";
import image8 from "../../images/facility/skill training/image8.JPG";
import image9 from "../../images/facility/skill training/image9.jpg";
import image10 from "../../images/facility/skill training/image10.JPG";
import image11 from "../../images/facility/skill training/image11.JPG";

export class Skill extends Component {
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row facilities_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h2 className="title" style={{ color: "white" }}>Facilities</h2>
                    <div className="ttm-color-home">
                      <span><a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}><i className="ti ti-home" />&nbsp;&nbsp;Home</a></span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">KIN Facilities</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <section>
                  <div className="section-title text-left with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">Skill Development <span>Labs</span></h2>
                    </div>
                  </div>
                  <div className="container-xl">
                    <div className="row mt-4">
                      <div className="col-12">
                        <Slider className="slick_slider project-single" {...slick_slider} arrows={false} dots={true}>
                          <div className="featured-thumbnail"><img src={image1} alt="image" className="img-fluid"/></div>
                          <div className="featured-thumbnail"><img src={image2} alt="image" className="img-fluid"/></div>
                          <div className="featured-thumbnail"><img src={image3} alt="image" className="img-fluid"/></div>
                          <div className="featured-thumbnail"><img src={image4} alt="image" className="img-fluid"/></div>
                          <div className="featured-thumbnail"><img src={image5} alt="image" className="img-fluid"/></div>
                          <div className="featured-thumbnail"><img src={image6} alt="image" className="img-fluid"/></div>
                          <div className="featured-thumbnail"><img src={image7} alt="image" className="img-fluid"/></div>
                          <div className="featured-thumbnail"><img src={image8} alt="image" className="img-fluid"/></div>
                          <div className="featured-thumbnail"><img src={image9} alt="image" className="img-fluid"/></div>
                          <div className="featured-thumbnail"><img src={image10} alt="image" className="img-fluid"/></div>
                          <div className="featured-thumbnail"><img src={image11} alt="image" className="img-fluid"/></div>
                        </Slider>
                        <p className="text-justify">
                          All the departmental lab of KIN are spacious,
                          ventilated, and well-equipped with modern amenities.
                          Facilities like cots with mattresses, charts, posters,
                          mannequins, advanced Nursing Models, instruments &
                          articles are provided in all the laboratories so that
                          students can comfortably hone skills to perform
                          various nursing procedures. Practical demonstrations
                          are given by skilled faculty to help students develop
                          excellent clinical competencies before serving
                          patients. The college has laboratory in Anatomy and
                          Physiology, Nursing Foundation, OBG, Pediatric,
                          Community, Nutrition, Physics and Chemistry to provide
                          hands-on practical experience in respective field.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-3 widget-area">
                <FacilitiesSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Skill;
