import React, { Component } from "react";
import Slider from "react-slick";
import Header from "../layout/Header";
import firestore from "../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import image1 from "../../images/alumni.jpg";
import NoData from "../NoData";

export class Alumni extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Alumni: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "Alumni");
    const notedkey = "Alumni";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      const allDynamicData = this.getDynamicData(Snapshot);
      this.dynamicData.Alumni = allDynamicData[notedkey];
    }
    const arr = [];
    this.setState({ Alumni: arr });
  }

  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row alumni_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Alumni KIN
                    </h4>

                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Alumni</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="ttm-row aboutus-section clearfix ">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Alumni <span></span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p className="text-justify">
                      To promote, strengthen and foster fellowship among the
                      former students of kempegowda institute of Nursing, we
                      planning to have the Alumni Association .The membership is
                      open to all graduates of kempegowda institute of Nursing.
                      The activities planned are Alumni reunions, workshops,
                      releasing a Newsletter and linking through Website and
                      E-mails.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image1}
                      alt="single-img-seven"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Alumni member-section  */}
        <section className=" blog-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Alumni <span>Member</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <Slider
              className="row slick_slider ttm-boxes-spacing-30px"
              {...slick_slider}
              slidesToShow={3}
            >
              {this.dynamicData.Alumni === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.Alumni.length &&
                this.dynamicData.Alumni.map(function (item, index) {
                  return (
                    <div className="ttm-box-col-wrapper" key={index}>
                      <div className="featured-imagebox featured-imagebox-blog style2">
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            alt=""
                            src={item.Image}
                            style={{ width: "360px", height: "330px" }}
                          />
                        </div>
                        <div className="featured-content">
                          <h6>{item.Name}</h6>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </section>

        <section className="ttm-row team-member-section clearfix  spacing_bottom">
          <div className="container-xl">
            <div className="row align-items-center mb-5">
              <div className="col-lg-5 res-991-text-center">
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header ">
                    <h2 className="title">
                      Alumni <span>Registeration</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="ttm-row only-one-section ttm-bgcolor-white clearfix">
                <div className="container-xl">
                  <div className="row">
                    <div className="col-lg-12">
                      <form
                        action="mailto:sushmasundar96@gmail.com"
                        method="post"
                        role="form"
                        name="checkout"
                        className="checkout row"
                      >
                        <div className="col-lg-6">
                          <h3>Registeration Form</h3>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>
                                  {" "}
                                  Name<abbr>*</abbr>
                                </label>
                                <input
                                  type="text"
                                  name="fname"
                                  className="form-control border"
                                />
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>
                                  City<abbr>*</abbr>
                                </label>
                                <input
                                  type="text"
                                  name="cname"
                                  className="form-control border"
                                />
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>
                                  Mobile<abbr>*</abbr>
                                </label>
                                <input
                                  type="text"
                                  name="cname"
                                  className="form-control border"
                                />
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>
                                  Email<abbr>*</abbr>
                                </label>
                                <input
                                  type="text"
                                  name="address"
                                  className="form-control border"
                                />
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>
                                  Year of Passing<abbr>*</abbr>
                                </label>
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control border"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label style={{ marginTop: "50px" }}>
                                  Address <abbr>*</abbr>
                                </label>
                                <textarea
                                  name="order"
                                  className="form-control border"
                                  rows={1}
                                  defaultValue={""}
                                />
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>
                                    State<abbr>*</abbr>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control border"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>
                                    Postal Code<abbr>*</abbr>
                                  </label>
                                  <input
                                    type="text"
                                    name="zip"
                                    className="form-control border"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>
                                    Country<abbr>*</abbr>
                                  </label>
                                  <input
                                    type="text"
                                    name="phone"
                                    className="form-control border"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>
                                    Gender<abbr>*</abbr>
                                  </label>
                                  <select
                                    name="billing_state"
                                    id="billing_state"
                                    className="form-control border"
                                  >
                                    <option value>Select a Gender…</option>
                                    <option value="0"> Male</option>
                                    <option value="1"> Female</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-12">
                      <div className="text-left">
                        <button
                          type="submit"
                          id="submit"
                          className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-aqua header"
                          value
                          style={{ marginLeft: "500px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Alumni;
