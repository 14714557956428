import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../../../layout/Header";
import firestore from "../../../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import MentalHealthFaculties from "./MentalHealthFaculties";
import MentalHealthResearch from "./MentalHealthResearch";
import image1 from "../../../../images/Department/MentalHealth/image1.jpg";
import image2 from "../../../../images/Department/MentalHealth/image2.png";
import image3 from "../../../../images/Department/MentalHealth/image3.jpg";
import NoData from "../../../NoData";

export class MentalHealthDept extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      HOD: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "MentalHealth");
    const Hodkey = "HOD";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      const allDynamicData = this.getDynamicData(Snapshot);
      this.dynamicData.HOD = allDynamicData[Hodkey];
    }
    const arr = [];
    this.setState({ HOD: arr });
  }

  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row mentalhealth_banner ">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Mental Health Nursing
                    </h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          href={process.env.PUBLIC_URL + "/Department"}
                        >
                          Department
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* aboutus-section */}
        <section className="ttm-row aboutus-section clearfix bottomzero-padding-section">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title  with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title ">
                      {" "}
                      Department of Mental Health <span>Nursing </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className=" aboutus-section clearfix zero-padding-section">
          <div className="sidebar ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-lg-12 content-area">
                  <div className="ttm-single-product-details product">
                    <div className="ttm-tabs tabs-for-single-products">
                      <Tabs>
                        <TabList>
                          <Tab>
                            <span style={{ padding: "20px" }}>About</span>
                          </Tab>
                          <Tab>
                            <span style={{ padding: "20px" }}>
                              Head Of Department
                            </span>
                          </Tab>
                          <Tab>
                            <span style={{ padding: "30px" }}>
                              Faculty and Staff
                            </span>
                          </Tab>
                          <Tab>
                            <span style={{ padding: "40px" }}>
                              {" "}
                              Research and Innovations
                            </span>
                          </Tab>
                        </TabList>
                        <div className="border_content-tab">
                          <TabPanel>
                            <div className="content-inner">
                              <h2></h2>
                              <p className="text-justify">
                                Psychiatric department has been actively
                                involved in clinical care, training students
                                from various streams, research and community
                                work. The students are benefited from this
                                approach of care and are better skills •
                                Involved in treating patients with mental health
                                problems and also liaises with other departments
                                of the hospital in managing psychological issues
                                in medically ill. • Involved in training
                                undergraduate and postgraduate students. • The
                                faculty in the department are experienced and
                                involved in imparting skills and knowledge to
                                the students and dedicated to patient care.
                              </p>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="section-title  with-desc text-left clearfix">
                                    <div className="title-header">
                                      <h2 className="title ">Vision </h2>
                                    </div>
                                  </div>
                                  <p className="mb-35 res-991-mb-20">
                                    Psychiatric nursing focus on provision of
                                    promotive, preventive, curative and
                                    rehabilitative aspects of care to mentally
                                    ill patients across the life span in a wide
                                    variety of healthcare settings.
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <div className="section-title  with-desc text-left clearfix">
                                    <div className="title-header">
                                      <h2 className="title ">Mission </h2>
                                    </div>
                                  </div>
                                  <p className="mb-35 res-991-mb-20">
                                    1. Applying principles and standards of
                                    mental health nursing and skill in
                                    application of nursing process in assessment
                                    and care of patients with mental health
                                    disorders. 2. Promoting students to practice
                                    evidence-based practice in the care of
                                    mentally ill patients and preventing mental
                                    illness in the community 3. Overall
                                    development of nursing students as par with
                                    national and international standards by
                                    focusing on Psychiatric related problems. 4.
                                    Preparing the students to overcome stressful
                                    life events through practicing effective
                                    coping mechanisms. 5. Promoting students
                                    apply knowledge in the promotion of mental
                                    health and prevention of mental illness.
                                  </p>
                                </div>
                              </div>
                              <div className="row multi-columns-row ttm-boxes-spacing-5px">
                                <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                                  <div className="featured-imagebox featured-imagebox-portfolio ">
                                    <div className="featured-thumbnail">
                                      <img
                                        className="img-fluid"
                                        src={image1}
                                        alt="image"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                                  <div className="featured-imagebox featured-imagebox-portfolio ">
                                    <div className="featured-thumbnail">
                                      <img
                                        className="img-fluid"
                                        src={image2}
                                        alt="image"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                                  <div className="featured-imagebox featured-imagebox-portfolio ">
                                    <div className="featured-thumbnail">
                                      <img
                                        className="img-fluid"
                                        src={image3}
                                        alt="image"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              {this.dynamicData.HOD === undefined ? (
                                <NoData />
                              ) : (
                                this.dynamicData.HOD.length &&
                                this.dynamicData.HOD.map(function (
                                  item,
                                  index
                                ) {
                                  return (
                                    <div className="row">
                                      <div className="col-lg-6 col-xl-6">
                                        <div className="ttm_single_image-wrapper mb-35">
                                          <img
                                            className="img-fluid"
                                            src={item.Image}
                                            title="single-img-twenty"
                                            alt="single-img-twenty"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-6">
                                        <div className="ttm-pf-single-detail-box mb-35">
                                          <div className="ttm-pf-single-title">
                                            <h5>{item.Name}</h5>
                                          </div>
                                          <ul className="ttm-pf-detailbox-list">
                                            <li>
                                              <i className="fa fa-user" />
                                              <span> Designation : </span>
                                              {item.Designation}{" "}
                                            </li>
                                            <li>
                                              <i className="far fa-bookmark" />
                                              <span> qualification :</span>
                                              {item.Qualification}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <MentalHealthFaculties />
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <MentalHealthResearch />
                            </div>
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default MentalHealthDept;
