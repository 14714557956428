import React, { Component } from "react";
import Header from "./layout/Header";

export default class Contact extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row contact-banner ">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>Contact</h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">contact</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="ttm-row zero-padding-section clearfix">
          <div className="container-xl">
            <div className="row no-gutters">
              <div className="col-lg-5">
                <div className="spacing-9">
                  <div className="section-title with-desc clearfix">
                    <div className="title-header"><h2 className="title">Address</h2></div>
                  </div>
                  <div className="featured-icon-box style2 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-bgcolor-aqua ttm-icon_element-size-md rounded-circle">
                        <i className="ti-location-pin" style={{ color: "White" }} />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title"><h5>Address</h5></div>
                      <div className="featured-desc">
                        <p>KEMPEGOWDA INSTITUTE OF NURSING</p>
                        <p>KR Road, VV Puram</p>
                        <p>BANGALORE- 560004</p>
                      </div>
                    </div>
                  </div>
                  <div className="separator"><div className="sep-line mt-4 mb-4" /></div>
                  <div className="featured-icon-box style2 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-bgcolor-aqua ttm-icon_element-size-md rounded-circle">
                        <i className="fa fa-phone" style={{ color: "White" }} />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title"><h5>Phone Number</h5></div>
                      <div className="featured-desc"><p>9448809024</p></div>
                    </div>
                  </div>
                  <div className="separator"><div className="sep-line mt-4 mb-4" /></div>
                  <div className="featured-icon-box style2 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-bgcolor-aqua ttm-icon_element-size-md rounded-circle">
                        <i className="ti ti-email" style={{ color: "White" }} />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title"><h5>Email</h5></div>
                      <div className="featured-desc"><p>kimsnursing1990@gmail.com</p></div>
                    </div>
                  </div>
                </div>
                <div className="separator"><div className="sep-line mt-4 mb-4" /></div>
              </div>
              <div className="col-lg-7">
                <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-bg-layer-inner" />
                  </div>
                  <div className="section-title with-desc clearfix">
                    <div className="title-header"><h2 className="title">Drop Us A Line</h2></div>
                  </div>
                  <form id="ttm-quote-form" className="row ttm-quote-form clearfix" method="post" action="#">
                    <div className="col-sm-6 col-md-6">
                      <div className="form-group">
                        <input name="name" type="text" className="form-control bg-white" placeholder="Full Name*" required="required" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <div className="form-group">
                        <input name="phone" type="number" placeholder="Phone Number*" required="required" className="form-control bg-white" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <div className="form-group">
                        <input name="address" type="email" placeholder="Email Address*" required="required" className="form-control bg-white" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <div className="form-group">
                        <input name="subject" type="text" placeholder="Subject" required="required" className="form-control bg-white" />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12">
                      <div className="form-group">
                        <textarea name="Massage" rows={5} placeholder="Write A Massage..." required="required" className="form-control bg-white" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="text-left">
                        <button type="submit" id="submit" className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-aqua header" value>Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ttm-row map-section res-991-p-0 clearfix">
          <div className="map-wrapper">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.2841351151205!2d77.57213211482177!3d12.95366159086769!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15e5588a7227%3A0x15f2a8c8a2fb5491!2sKempegowda%20Institute%20Of%20Nursing!5e0!3m2!1sen!2sin!4v1631514648644!5m2!1sen!2sin"
              height="100%"
            ></iframe>
          </div>
        </section>
      </div>
    );
  }
}
