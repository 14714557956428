import React, { Component } from "react";
import Header from "../layout/Header";
import firestore from "../../Config/Config";
import { doc, getDoc } from "firebase/firestore";
import NoData from "../NoData";
import Loading from '../Loading';

export default class Faculty extends Component {

  state = { Datas: [], Datas1: [] }

  //Load Data Here
  async componentDidMount() {
    //For Teaching
    const docRef = doc(firestore, "Faculty", "TeachingFaculty");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });
      console.log(Data.sort(function (a, b) { return a.Priority - b.Priority; }));
      Data.length === 0 ?
        this.setState({ Datas: "NO_999" }) :
        this.setState({ Datas: Data })
    } else {
      this.setState({
        Datas: "NO_999"
      })
    }

    //For Non Teaching
    const docRef1 = doc(firestore, "Faculty", "NonTeachingStaff");
    const docSnap1 = await getDoc(docRef1);

    if (docSnap1.exists()) {
      var Data = []
      Object.entries(docSnap1.data()).forEach(([key, value]) => {
        Data.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });
      console.log(Data.sort(function (a, b) { return a.Priority - b.Priority; }));
      Data.length === 0 ?
        this.setState({ Datas1: "NO_999" }) :
        this.setState({ Datas1: Data })
    } else {
      this.setState({
        Datas1: "NO_999"
      })
    }
  }

  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row conservative_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading  card_box">
                    <h4 className="title" style={{ color: "white" }}>Faculties and Staff</h4>
                    <div className="ttm-color-home">
                      <span>
                        <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Faculties of KIN </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-12 content-area order-lg-2">
                <section className="ttm-row grid-section clearfix">
                  <div className="container-xl">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header"><h2 className="title">Teaching <span>Faculties</span></h2></div>
                    </div>
                    <div className="row">
                      {
                        this.state.Datas.length === 0 ? <Loading /> :
                          this.state.Datas === "NO_999" ? <NoData /> :
                            this.state.Datas.map((item, index) => (
                              <div className="col-md-6 col-lg-4 col-sm-6" key={index}>
                                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                                  <div className="featured-thumbnail">
                                    <img className="img-fluid d-flex" src={item.Image} alt="image" />
                                  </div>
                                </div>
                                <div className="ttm-pf-single-detail-box mb-35">
                                  <ul className="ttm-pf-detailbox-list">
                                    <li><i className="fa fa-user" /><span>Name : </span>{item.Name}</li>
                                    <li><i className="far fa-bookmark" /><span>Designation :</span>{item.Designation}</li>
                                    <li><i className="fa fa-graduation-cap" /><span>Qualification :</span>{item.Qualification}</li>
                                  </ul>
                                </div>
                              </div>
                            ))
                      }
                    </div>
                  </div>
                </section>

                <section className="ttm-row grid-section clearfix">
                  <div className="container-xl">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header"><h2 className="title">Non Teaching <span> Staff</span></h2></div>
                    </div>
                    <div className="row">
                      {
                        this.state.Datas1.length === 0 ? <Loading /> :
                          this.state.Datas1 === "NO_999" ? <NoData /> :
                            this.state.Datas1.map((item, index) => (
                              <div className="col-md-6 col-lg-4 col-sm-6" key={index}>
                                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                                  <div className="featured-thumbnail">
                                    <img className="img-fluid d-flex" src={item.Image} alt="image" />
                                  </div>
                                </div>
                                <div className="ttm-pf-single-detail-box mb-35">
                                  <ul className="ttm-pf-detailbox-list">
                                    <li><i className="fa fa-user" /><span>Name : </span>{item.Name}</li>
                                    <li><i className="far fa-bookmark" /><span>Designation :</span>{item.Designation}</li>
                                    <li><i className="fa fa-graduation-cap" /><span>Qualification :</span>{item.Qualification}</li>
                                  </ul>
                                </div>
                              </div>
                            ))
                      }
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
