import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../layout/Header";
import image from "../../images/admission_process.JPG";
import firestore from "../../Config/Config";
import { doc, getDoc } from "firebase/firestore";
import prospectus from "../../assets/Admission/Collegebroucher.pdf";
import pdf from "../../assets/Admission/PB.BScApplication.pdf";
import NoData from "../NoData";
import Loading from '../Loading';

export class Diploma extends Component {

  state = { Datas: [] }

  //Load Data Here
  async componentDidMount() {
    //For Teaching
    const docRef = doc(firestore, "Admission", "Diploma");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });
      console.log(Data.sort(function (a, b) { return a.Priority - b.Priority; }));
      Data.length === 0 ?
        this.setState({ Datas: "NO_999" }) :
        this.setState({ Datas: Data })
    } else {
      this.setState({
        Datas: "NO_999"
      })
    }
  }

  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row admission_banner ">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>Admission</h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                          <i className="ti ti-home" />&nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">Admission</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="ttm-row aboutus-section clearfix ">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Admission for <span>Diploma</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      The duration of Diploma in General Nursing [GNM] program
                      run under KSDNEB (Karnataka State Diploma Nursing
                      Education Board). It is 3 years course which includes
                      classroom learning and practical hours. Clinical
                      Facilities are provided to students at KIMS Hospital under
                      the guidance of faculty in various clinical and community
                      settings. The board exams are held annually.
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white ttm-bgcolor-aqua"
                    href={prospectus}
                    target="newWindow"
                    style={{ backgroundColor: "#009c9f" }}
                  >
                    KIN Prospectus
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image}
                      alt="single-img-seven"
                      style={{ width: "500px", height: "400px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row aboutus-section clearfix zero-padding-section">
          <div className="sidebar ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-lg-12 content-area">
                  <div className="ttm-single-product-details product">
                    <div className="ttm-tabs tabs-for-single-products">
                      <Tabs>
                        <TabList>
                          <Tab>
                            <span>Seats Available</span>
                          </Tab>
                          <Tab>
                            <span>Eligibility</span>
                          </Tab>
                          <Tab>
                            <span>Documents to be submitted</span>
                          </Tab>
                          <Tab>
                            <span>Download Application for Admission</span>
                          </Tab>
                        </TabList>
                        <div className="border_content-tab">
                          <TabPanel>
                            <div className="content-inner">
                              <h2>Seats Available</h2>
                              <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor aqua-color" />Courses offered: General Nursing& Midwifery</li>
                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor aqua-color" />Graduation: DIPLOMA</li>
                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor aqua-color" />Duration: 3 years</li>
                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor aqua-color" />Intake per year: 100 (Management seat: 90 Govt seat: 10)</li>
                              </ul>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <h6>Minimum education eligibility criteria for admission to GNM:</h6>
                              <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                <li>
                                  1. 10+2 with English and must have obtained a
                                  minimum of 40% at the qualifying examination
                                  and English individually for any recognized
                                  board. Candidates are also eligible from State
                                  Open School recognized. Candidates are also
                                  eligible from State Open School recognized by
                                  State Government and National Institute of
                                  Open School (NIOS) recognized by Central
                                  Government. However Science is preferable.
                                </li>
                                <li>
                                  2. 10+2 with English having 40% of marks in
                                  vocational ANM course from the school
                                  recognized by Indian Nursing council.
                                </li>
                                <li>
                                  3. 10+2 with English having 40% of marks in
                                  Vocational Stream-Health care Science from a
                                  recognized CBSE board/Centre.
                                </li>
                                <li>4. Students shall be medically fit.</li>
                                <li>
                                  5. Minimum age for admission will be 17 years.
                                  (as on 31st December of that year) The upper
                                  age limit is 35 yrs. For ANM/ for LHV, there
                                  is no age bar.
                                </li>
                              </ul>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <h6>
                                Documents to be submitted During Admission
                              </h6>
                              <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                  1. Transfer Certificate.
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                  2. Cumulative Records or SSLC / X Standard
                                  Marks Card.
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                  3. Marks Card Qualifying Exam. Passed or its
                                  Equivalent (PUC/PDC).
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                  4. Medical Certificate.
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                  5. Caste Certificate (Vokkaliga and SC / ST.
                                  Backward Classes)
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                  6. Character and Conduct Certificate from Head
                                  of Pervious Institution.
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                  7. Three copies recent passport size
                                  photographs
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                  8. Migration Certificate. (Other than
                                  Karnataka State Candidates only)
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                  9. Passport Copy of above mentioned
                                  certificates.
                                </li>
                              </ul>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <h6><a href={pdf} download>Click Here to Download Application Form</a></h6>
                            </div>
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className=" aboutus-section clearfix">
          <div className=" only-one-section ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h2 className="title">Fee <span> Structure</span></h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <table className="shop_table shop_table_responsive">
                    <thead className="th_color" style={{ fontSize: "1rem", textAlign: "center" }}>
                      <tr>
                        <th>Course</th>
                        <th>Year</th>
                        <th>Tution fee - Management</th>
                        <th>Tution fee - Govt</th>
                        <th>Mess charges</th>
                        <th>SNA</th>
                        <th>SWF</th>
                        <th>Uniform Fee</th>
                        <th>TNAI membership Fee</th>
                        <th>KSDNEB Fee</th>
                        <th>Application Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.Datas.length === 0 ? <Loading /> :
                          this.state.Datas === "NO_999" ? <NoData /> :
                            this.state.Datas.map((item, index) => (
                              <tr className={`cart_item tb-content ${index % 2 == 0 ? " table-bgcolor_blue" : "table-bgcolor_white"}`} key={index} style={{ fontSize: "1rem", textAlign: "center" }}>
                                <td><h6>{item.Course}</h6></td>
                                <td><h6>{item.Year}</h6></td>
                                <td><h6>{item.TutionManagement}</h6></td>
                                <td><h6>{item.TutionGovt}</h6></td>
                                <td><h6>{item.Mess}</h6></td>
                                <td><h6>{item.SNA}</h6></td>
                                <td><h6>{item.SWF}</h6></td>
                                <td><h6>{item.Uniform}</h6></td>
                                <td><h6>{item.TNAI}</h6></td>
                                <td><h6>{item.KSDNEB}</h6></td>
                                <td><h6>{item.Application}</h6></td>
                              </tr>
                            ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Diploma;
