import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ScrollToTop from "./components/layout/Gotop";
import Home from "./components/Home";

import ManagementRVS from "./components/Explore/ManagementRVS";
import AboutKIN from "./components/Explore/AboutKIN";
import ChairmanKIN from "./components/Explore/ChairmanKIN";
import PrincipalKIN from "./components/Explore/PrincipalKIN";

import Facilities from "./components/Explore/Facilities";
import Skill from "./components/Explore/Skill";
import ClassRoom from "./components/Explore/ClassRooms";
import Clinical from "./components/Explore/Clinical";
import Committees from "./components/Explore/Committees";
import Transport from "./components/Explore/Transport";
import Hostel from "./components/Explore/Hostel";

import Community from "./components/Explore/Community";

import Diploma from "./components/Admission/Diploma";
import BscNur from "./components/Admission/BScNur";
import PBsc from "./components/Admission/PBsc";
import MScNur from "./components/Admission/MSc";
import Npcc from "./components/Admission/Npcc";

import Department from "./components/Academics/Department/Department";
import CommunityDept from "./components/Academics/Department/Community/Community";
import MedicalDept from "./components/Academics/Department/MedicalSurgDept/Medicaldept";
import ObstetricsDept from "./components/Academics/Department/ObstetricsGynae/ObstetricsDept";
import ChildHealthDept from "./components/Academics/Department/ChildHealthDept/ChildHealthDept";
import MentalHealthDept from "./components/Academics/Department/MentalHealthDept/MentalHealthDept";
import NursingFnDept from "./components/Academics/Department/NursingFnDept/NursingFnDept";

import Faculty from "./components/Academics/Faculty";
import EachFaculties from "./components/Academics/Department/EachFaculty";
import TeachingLearning from "./components/Academics/TeachingLearning";
import Achievers from "./components/Academics/Achievers";
import Alumni from "./components/Academics/Alumni";

import Gallery from "./components/Gallery/Gallery";
import Contact from "./components/Contact";

import Footer from "./components/layout/Footer";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={Home} />

      <Route path={`${process.env.PUBLIC_URL + "/managementrvs"}`} component={ManagementRVS} />
      <Route path={`${process.env.PUBLIC_URL + "/about"}`} component={AboutKIN} />
      <Route path={`${process.env.PUBLIC_URL + "/chairman"}`} component={ChairmanKIN} />
      <Route path={`${process.env.PUBLIC_URL + "/principal"}`} component={PrincipalKIN} />

      <Route path={`${process.env.PUBLIC_URL + "/facilities"}`} component={Facilities} />
      <Route path={`${process.env.PUBLIC_URL + "/Skill"}`} component={Skill} />
      <Route path={`${process.env.PUBLIC_URL + "/ClassRoom"}`} component={ClassRoom} />
      <Route path={`${process.env.PUBLIC_URL + "/Clinical"}`} component={Clinical} />
      <Route path={`${process.env.PUBLIC_URL + "/Committees"}`} component={Committees} />
      <Route path={`${process.env.PUBLIC_URL + "/Transport"}`} component={Transport} />
      <Route path={`${process.env.PUBLIC_URL + "/Hostel"}`} component={Hostel} />

      <Route path={`${process.env.PUBLIC_URL + "/Community"}`} component={Community} />

      <Route path={`${process.env.PUBLIC_URL + "/Diploma"}`} component={Diploma} />
      <Route path={`${process.env.PUBLIC_URL + "/BScNur"}`} component={BscNur} />
      <Route path={`${process.env.PUBLIC_URL + "/PBsc"}`} component={PBsc} />
      <Route path={`${process.env.PUBLIC_URL + "/MSc"}`} component={MScNur} />
      <Route path={`${process.env.PUBLIC_URL + "/NPCC"}`} component={Npcc} />

      <Route path={`${process.env.PUBLIC_URL + "/department"}`} component={Department} />
      <Route path={`${process.env.PUBLIC_URL + "/Communitydept"}`} component={CommunityDept} />
      <Route path={`${process.env.PUBLIC_URL + "/MedicalSugicaldept"}`} component={MedicalDept} />
      <Route path={`${process.env.PUBLIC_URL + "/ObstetricsGynaeDept"}`} component={ObstetricsDept} />
      <Route path={`${process.env.PUBLIC_URL + "/ChildHealthdept"}`} component={ChildHealthDept} />
      <Route path={`${process.env.PUBLIC_URL + "/MentalHealthdept"}`} component={MentalHealthDept} />
      <Route path={`${process.env.PUBLIC_URL + "/NursingFnDept"}`} component={NursingFnDept} />

      <Route path={`${process.env.PUBLIC_URL + "/academicsFaculties"}`} component={Faculty} />
      <Route path={`${process.env.PUBLIC_URL + "/EachFaculty/:id/:deptname"}`} component={EachFaculties} />
      <Route path={`${process.env.PUBLIC_URL + "/teachinglearning"}`} component={TeachingLearning} />
      <Route path={`${process.env.PUBLIC_URL + "/achievers"}`} component={Achievers} />
      <Route path={`${process.env.PUBLIC_URL + "/alumni"}`} component={Alumni} />

      <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} component={Gallery} />
      <Route path={`${process.env.PUBLIC_URL + "/Contact"}`} component={Contact} />
      <Footer />
    </Router>
  );
};

export default App;
