import React, { Component } from "react";
import Header from "../layout/Header";
import image from "../../images/image12.JPG";

export class PrincipalKIN extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row principalbanner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Principal
                    </h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                          className="ttm-color-home"
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">KIN</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Principal-section */}
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="section-title with-desc clearfix"
                  style={{ paddingBlockEnd: "40px" }}
                >
                  <div className="title-header">
                    <h2 className="title">
                      Principal<span> Message</span>
                    </h2>
                  </div>
                  <div className="title-desc-ff">
                    <p className="text-justify">
                      I feel great pleasure to introduce Kempegowda Institute of
                      Nursing (KIN) as a renowned nursing institution which was
                      established in 1990 to provide quality education and
                      excellence in the ever changing field of nursing
                      education. Technology is moving at a very fast pace. What
                      was a breakthrough yesterday is obsolete today. This has
                      made it imperative that future nurses must be familiar not
                      only with technical skills but also involve in nursing
                      research which is the need of the hour. The theory
                      “Survival of fittest” is more relevant now than ever
                      before. We believe in value based quality nursing
                      education and faculty members of KIN are striving hard for
                      it, so that product of KIN is well received by the
                      HealthCare Industry. I hope young nurses passing from the
                      institute will create differences in Indian and Global
                      scenarios. I expect my students to be sincere, responsible
                      and committed to their work and develop “never give up
                      attitude and unquenchable thirst for knowledge”. I welcome
                      you to be a part in making the institute a real instrument
                      of socio- economic change through quality nursing
                      education. I wish Best of Luck to all the students in
                      their future endeavors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <div className="featured-imagebox featured-imagebox-portfolio ">
                      <div className="featured-thumbnail">
                        <img
                          className="img-fluid"
                          src={image}
                          alt="single-img-seven"
                        />
                      </div>
                      <div>
                        <div>
                          <br />
                        </div>
                        <div>
                          <div
                            className="member-info-box border"
                            style={{ padding: "10px", textAlign: "left" }}
                          >
                            <div className="timing-box">
                              <h4>DR. V.T. LAKSHMAMMA </h4>
                            </div>
                            <ul className="info-list">
                              <li>PRINCIPAL MSc(N),PhD(N)</li>
                              <li><i className="ti ti-location-pin" /><span> KIN College & Hospital Bengaluru.</span></li>
                              <li><i className="ti ti-mobile" /><span> Phone: 9448809024</span></li>
                              <li><i className="ti ti-comment" /><span>College Email: kimsnursing1990@gmail.com</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PrincipalKIN;
