import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../layout/Header";
import image from "../../images/admission_process.JPG";
import firestore from "../../Config/Config";
import { doc, getDoc } from "firebase/firestore";
import pdf from "../../assets/Admission/PB.BScApplication.pdf";
import prospectus from "../../assets/Admission/Collegebroucher.pdf";
import NoData from "../NoData";
import Loading from "../Loading";

export class PBsc extends Component {
  state = { Datas: [] };

  //Load Data Here
  async componentDidMount() {
    //For Teaching
    const docRef = doc(firestore, "Admission", "PBscFee");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = [];
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(
          Object.assign(
            {
              Id: key,
              Priority: value.Priority != undefined ? value.Priority : "999",
            },
            value
          )
        );
      });
      console.log(
        Data.sort(function (a, b) {
          return a.Priority - b.Priority;
        })
      );
      Data.length === 0
        ? this.setState({ Datas: "NO_999" })
        : this.setState({ Datas: Data });
    } else {
      this.setState({
        Datas: "NO_999",
      });
    }
  }

  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row admission_banner ">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Admission
                    </h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">Admission</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* aboutus-section */}
        <section className="ttm-row aboutus-section clearfix ">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Admission for <span> P.B. BSc</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      The duration of PB BSc Nursing program under RGUHS is of
                      four years, and the nursing course includes classroom
                      learning and practical HOURS. Facilities are provided to
                      students for clinical experiences under the guidance of
                      faculty in various clinical and community settings. The
                      university exams are held annually.
                    </p>
                  </div>
                </div>
                <div>
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white ttm-bgcolor-aqua"
                    ref={prospectus}
                    target="newWindow"
                    style={{ backgroundColor: "#009c9f" }}
                  >
                    KIN Prospectus
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image}
                      alt="single-img-seven"
                      style={{ width: "500px", height: "400px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row aboutus-section clearfix zero-padding-section">
          <div className="sidebar ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-lg-12 content-area">
                  <div className="ttm-single-product-details product">
                    <div className="ttm-tabs tabs-for-single-products">
                      <Tabs>
                        <TabList>
                          <Tab>
                            <span>Seats Available</span>
                          </Tab>
                          <Tab>
                            <span>Eligibility</span>
                          </Tab>
                          <Tab>
                            <span>Documents to be submitted</span>
                          </Tab>
                          <Tab>
                            <span>Download Application for Admission</span>
                          </Tab>
                        </TabList>
                        <div className="border_content-tab">
                          <TabPanel>
                            <div className="content-inner">
                              <h2>Seats Available</h2>
                              <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor aqua-color" />
                                  Courses offered: BSC (N)
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor aqua-color" />
                                  Graduation: Under graduation
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor aqua-color" />
                                  Duration: 2 years
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor aqua-color" />
                                  Intake per year: 50 (Management seat - 50,
                                  Govt seat: nill)
                                </li>
                              </ul>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <h6>
                                Minimum education eligibility criteria for
                                admission to BSC(N)
                              </h6>
                              <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                <li>
                                  1. The minimum age for admission shall be 17
                                  years on 31st December of the year in which
                                  admission is sought.
                                </li>
                                <li>
                                  2. Minimum education:10+2 class passed with
                                  Science (PCB) & English Core/English Elective
                                  with aggregate of 45% marks from recognized
                                  board under AISSCE/CBSE/ICSE/SSCE/HSCE or
                                  other equivalent Board.
                                </li>
                                <li>3. Student shall be medically fit.</li>
                                <li>
                                  4. Students appearing in 10+2 examination in
                                  Science conducted by National Institute of
                                  Open School with 45% marks.
                                </li>
                                <li>
                                  5. Student shall be admitted once in a year
                                </li>
                              </ul>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <h6>
                                Documents to be submitted During Admission
                              </h6>
                              <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                <li>1. S.S.L.C Marks Card</li>
                                <li>
                                  2. PUC and higher qualification if any
                                  Certificate
                                </li>
                                <li>3. Marks Cards of Diploma Nursing</li>
                                <li>4. Certificate of Diploma Nursing</li>
                                <li>5. Nursing Council Certificate</li>
                                <li>
                                  6. Extract of Date of Birth from SSLC
                                  Certificate.
                                </li>
                                <li>
                                  7. Extract of Date of entry into service from
                                  service Register
                                </li>
                                <li>
                                  8. Caste Certificate of you belong to SC/ST or
                                  Backward Tribe in Form No.1
                                </li>
                              </ul>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <h6>
                                <a href={pdf} download>
                                  Click Here to Download Application Form
                                </a>
                              </h6>
                            </div>
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className=" aboutus-section clearfix">
          <div className=" only-one-section ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h2 className="title">
                    Fee <span> Structure</span>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <table className="shop_table shop_table_responsive">
                    <thead
                      className="th_color"
                      style={{ fontSize: "1rem", textAlign: "center" }}
                    >
                      <tr>
                        <th>Course</th>
                        <th>Year</th>
                        <th>Tution fee</th>
                        <th>Miscellaneous Fee</th>
                        <th>SNA</th>
                        <th>SWF</th>
                        <th>University Fee</th>
                        <th>Application Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.Datas.length === 0 ? (
                        <Loading />
                      ) : this.state.Datas === "NO_999" ? (
                        <NoData />
                      ) : (
                        this.state.Datas.map((item, index) => (
                          <tr
                            className={`cart_item tb-content ${
                              index % 2 == 0
                                ? " table-bgcolor_blue"
                                : "table-bgcolor_white"
                            }`}
                            key={index}
                            style={{ fontSize: "1rem", textAlign: "center" }}
                          >
                            <td>
                              <h6>{item.Course}</h6>
                            </td>
                            <td>
                              <h6>{item.Year}</h6>
                            </td>
                            <td>
                              <h6>{item.Tution}</h6>
                            </td>
                            <td>
                              <h6>{item.Miscellaneous}</h6>
                            </td>
                            <td>
                              <h6>{item.SNA}</h6>
                            </td>
                            <td>
                              <h6>{item.SWF}</h6>
                            </td>
                            <td>
                              <h6>{item.University}</h6>
                            </td>
                            <td>
                              <h6>{item.Application}</h6>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PBsc;
