import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../../../layout/Header";
import firestore from "../../../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import MedicalFaculties from "./MedicalFaculties";
import MedicalResearch from "./MedicalResearch";
import image1 from "../../../../images/Department/MedicalSurgery/image1.JPG";
import image2 from "../../../../images/Department/MedicalSurgery/image2.JPG";
import image3 from "../../../../images/Department/MedicalSurgery/image3.JPG";
import NoData from "../../../NoData";

export class MedicalDept extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      HOD: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "MedicalSurgical");
    const Hodkey = "HOD";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      const allDynamicData = this.getDynamicData(Snapshot);
      this.dynamicData.HOD = allDynamicData[Hodkey];
    }
    const arr = [];
    this.setState({ HOD: arr });
  }
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row medicalsurgical_banner ">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Medical Surgical Nursing
                    </h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">
                        {" "}
                        <a
                          className="ttm-color-home"
                          href={process.env.PUBLIC_URL + "/Department"}
                        >
                          {" "}
                          Department
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* aboutus-section */}
        <section className="ttm-row aboutus-section clearfix bottomzero-padding-section">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title  with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title ">
                      {" "}
                      Department of Medical Surgical <span>Nursing </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className=" aboutus-section clearfix zero-padding-section">
          <div className="sidebar ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-lg-12 content-area">
                  <div className="ttm-single-product-details product">
                    <div className="ttm-tabs tabs-for-single-products">
                      <Tabs>
                        <TabList>
                          <Tab>
                            <span style={{ padding: "20px" }}>About</span>
                          </Tab>
                          <Tab>
                            <span style={{ padding: "20px" }}>
                              Head Of Department
                            </span>
                          </Tab>
                          <Tab>
                            <span style={{ padding: "30px" }}>
                              Faculty and Staff
                            </span>
                          </Tab>
                          <Tab>
                            <span style={{ padding: "40px" }}>
                              {" "}
                              Research and Innovations
                            </span>
                          </Tab>
                        </TabList>
                        <div className="border_content-tab">
                          <TabPanel>
                            <div className="content-inner">
                              <h2></h2>
                              <p className="text-justify">
                                Medical surgical nursing is a nursing specialty
                                area concerned with the care of adult patients
                                in broad range of settings. The academy Medical
                                surgical nurses (AMSN) is a specialty nursing
                                organizations dedicated to nurturing medical
                                surgical nurses as they advances their carriers.
                                Today lenience Medical surgical nurses work in a
                                various positions, in patients clinics,
                                emergency department, HMO’S, administration,
                                outpatient surgical centers, home health care,
                                humanitarian relief work, ambulatory surgical
                                care and skilled nursing homes. Some military
                                medical surgical nurses serve on battlefield.
                              </p>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="section-title  with-desc text-left clearfix">
                                    <div className="title-header">
                                      <h2 className="title ">Vision </h2>
                                    </div>
                                  </div>
                                  <p className="mb-35 res-991-mb-20">
                                    Actively strive to meet the excellence in
                                    education, practice and research related to
                                    health needs of the individual, family and
                                    community to strengthen and maximize the
                                    potential of nursing students in the field
                                    of evolving health care context.
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <div className="section-title  with-desc text-left clearfix">
                                    <div className="title-header">
                                      <h2 className="title ">Mission </h2>
                                    </div>
                                  </div>
                                  <p className="mb-35 res-991-mb-20">
                                    1. To equip students acquire in depth
                                    knowledge and skills needed to function at
                                    different settings in providing curative,
                                    preventive, promotive and rehabilitative
                                    care. 2. Promote research oriented nursing
                                    practice to prepare our students to be
                                    leaders in the field of medical surgical
                                    nursing. 3. Update the students and faculty
                                    of change in protocols and polices in field
                                    of medical surgical nursing.
                                  </p>
                                </div>
                              </div>
                              <div className="row multi-columns-row ttm-boxes-spacing-5px">
                                <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                                  <div className="featured-imagebox featured-imagebox-portfolio ">
                                    <div className="featured-thumbnail">
                                      <img
                                        className="img-fluid"
                                        src={image1}
                                        alt="image"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                                  <div className="featured-imagebox featured-imagebox-portfolio ">
                                    <div className="featured-thumbnail">
                                      <img
                                        className="img-fluid"
                                        src={image2}
                                        alt="image"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                                  <div className="featured-imagebox featured-imagebox-portfolio ">
                                    <div className="featured-thumbnail">
                                      <img
                                        className="img-fluid"
                                        src={image3}
                                        alt="image"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              {this.dynamicData.HOD === undefined ? (
                                <NoData />
                              ) : (
                                this.dynamicData.HOD.length &&
                                this.dynamicData.HOD.map(function (
                                  item,
                                  index
                                ) {
                                  return (
                                    <div className="row">
                                      <div className="col-lg-6 col-xl-6">
                                        <div className="ttm_single_image-wrapper mb-35">
                                          <img
                                            className="img-fluid"
                                            src={item.Image}
                                            title="single-img-twenty"
                                            alt="single-img-twenty"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-6">
                                        <div className="ttm-pf-single-detail-box mb-35">
                                          <div className="ttm-pf-single-title">
                                            <h5>{item.Name}</h5>
                                          </div>
                                          <ul className="ttm-pf-detailbox-list">
                                            <li>
                                              <i className="fa fa-user" />
                                              <span> Designation : </span>
                                              {item.Designation}{" "}
                                            </li>
                                            <li>
                                              <i className="far fa-bookmark" />
                                              <span> qualification :</span>
                                              {item.Qualification}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <MedicalFaculties />
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <MedicalResearch />
                            </div>
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default MedicalDept;
