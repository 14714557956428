import React, { Component } from "react";
import FacilitiesSidebar from "./FacilitiesSidebar";
import Header from "../layout/Header";
import Slider from "react-slick";
import image1 from "../../images/facility/hostel/image1.JPG";
import image2 from "../../images/facility/hostel/image2.JPG";
import image3 from "../../images/facility/hostel/image3.JPG";
import image4 from "../../images/facility/hostel/image4.JPG";
import image5 from "../../images/facility/hostel/image5.JPG";
import image6 from "../../images/facility/hostel/image6.jpg";
import image7 from "../../images/facility/hostel/image7.JPG";
import image8 from "../../images/facility/hostel/image8.JPG";
import image9 from "../../images/facility/hostel/image9.JPG";

export class Hostel extends Component {

  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row facilities_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h2 className="title" style={{ color: "white" }}>Facilities</h2>
                    <div className="ttm-color-home">
                      <span><a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}><i className="ti ti-home" />&nbsp;&nbsp;Home</a></span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">KIN Facilities</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title text-left with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">Hostel <span>Facilities</span></h2>
                  </div>
                </div>
                <Slider className="slick_slider project-single" {...slick_slider} arrows={false} dots={true}>
                  <div className="featured-thumbnail"><img src={image1} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image2} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image3} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image4} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image5} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image6} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image7} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image8} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image9} alt="image" className="img-fluid" /></div>
                </Slider>
                <br />
                <p className="text-justify">
                KIN has a comfortable, secure and reasonably priced on campus hostel facility only for girl students. Spacious ventilated rooms, 24 hour power back up, CCTV cameras at entrances of all hostels and inside the campus, 24x7 electricity and water supply, Solar water heaters for hot water, TV room, RO water purifiers for drinking water and ATM facility in the campus premises are the major facilities available in the hostel. Full time security & vigil is maintained by the security department. Caretakers and housekeeping services are provided during day and night. To ensure the quality and choice of food, hostel mess is managed by students committee under the supervision of hostel warden, supervisor, manager, and Principal of KIN.
                </p>
              </div>
              <div className="col-lg-3 widget-area">
                <FacilitiesSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hostel;
