import React, { Component } from "react";
import pdf from "../../assets/Recognition-KIN.pdf";
import pdf1 from "../../assets/Affiliation-KIN.pdf";
import pdf2 from "../../assets/c and r rules.pdf";
import { Link } from "react-router-dom";

export class Sidebar extends Component {
  render() {
    return (
      <div>
        <div className="widget widget-nav-menu">
          <h6>KIN Departments</h6>
          <ul className="widget-menu">
            <li><Link to={process.env.PUBLIC_URL + "/Communitydept"}>Community Health Nursing</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/MedicalSugicaldept"}>Medical Surgical Nursing</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/ObstetricsGynaeDept"}>Obstetrics and Gynaecological Nursing</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/ChildHealthdept"}>Child Health Nursing</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/MentalHealthdept"}>Mental Health Nursing</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/NursingFnDept"}>Fundamentals of Nursing</Link></li>
          </ul>
        </div>
        <div className="widget widget-download">
          <h3 className="widget-title">Rules and Regulations</h3>
          <ul className="download">
            <li><i className="far fa-file-pdf" /><a className="ttm-btn btn-inline style2" href={pdf1} target="_blank" rel="noopener noreferrer" download>KIN Service Rules</a></li>
            <li><i className="far fa-file-pdf" /><a className="ttm-btn btn-inline style2" href={pdf2} target="_blank" rel="noopener noreferrer" download>C and R Rules</a></li>
          </ul>
        </div>
        <div className="widget widget-download">
          <h3 className="widget-title">Affiliations</h3>
          <ul className="download">
            <li><i className="far fa-file-pdf" /><a className="ttm-btn btn-inline style2" href={pdf1} target="_blank" rel="noopener noreferrer" download>Affiliation KIN</a></li>
            <li><i className="far fa-file-pdf" /><a className="ttm-btn btn-inline style2" href={pdf} target="_blank" rel="noopener noreferrer" download>Recognition KIN</a></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
