import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Menu extends Component {
  render() {
    return (
      <nav id="menu" role="navigation" className="menu">
        <ul className="header">
          <li className="menu-hasdropdown">
            <Link className="nav-link header" to={process.env.PUBLIC_URL + "/"} style={{color:"white"}}>Home</Link>
          </li>
          <li className="menu-hasdropdown ">
            <a style={{color:"white"}}>Explore Us</a>
            <ul className="menu-dropdown">
              <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/managementRVS"}>Management RVS</Link></li>
              <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/about"}>About</Link></li>
              <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/chairman"}>Chairman</Link></li>
              <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/principal"}>Principal</Link></li>
              <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/facilities"}>Facilities</Link></li>
              <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/committees"}>Committees</Link></li>
            </ul>
          </li>
          <li className="menu-hasdropdown">
            <a style={{color:"white"}}>Admission</a>
              <ul className="menu-dropdown">
                <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/diploma"}>Diploma</Link></li>
                <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/BScNur"}>BSc Nursing</Link></li>
                <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/PBsc"}>P.B.BSc</Link></li>
                <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/MSc"}>M.Sc. Nursing</Link></li>
                <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/NPCC"}>NPCC</Link></li>
              </ul>
          </li>
          <li className="menu-hasdropdown">
            <a style={{color:"white"}}>Academics</a>
            <ul className="menu-dropdown">
              <li className="menu-hasdropdown"><Link to={process.env.PUBLIC_URL + "/department"}>Departments</Link></li>
              <li className="menu-hasdropdown"><Link to={process.env.PUBLIC_URL + "/academicsFaculties"}>Faculty & Staff</Link></li>
              <li><Link to={process.env.PUBLIC_URL + "/teachingLearning"}>Teaching & Learning</Link></li>
              <li><Link to={process.env.PUBLIC_URL + "/achievers"}>Achievers</Link></li>
              <li><Link to={process.env.PUBLIC_URL + "/alumni"}>Alumni</Link></li>
            </ul>
          </li>
          <li className="menu-hasdropdown"><Link to={process.env.PUBLIC_URL + "/gallery"} style={{color:"white"}}>Gallery</Link></li>
          <li className="menu-hasdropdown"><Link to={process.env.PUBLIC_URL + "/contact"} style={{color:"white"}}>Contact</Link></li>
        </ul>
      </nav>
    );
  }
}
