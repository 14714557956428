import React, { Component } from "react";
import {MDBNavbar, MDBNavbarNav, MDBNavItem, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBHamburgerToggler } from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";

class Mobilemenu extends Component {

  state = { collapse1: false, collapseID: ""};

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  toggleSingleCollapse = (collapseId) => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId],
    });
  };

  render() {
    return (
      <Router>
        <MDBNavbar>
          <MDBHamburgerToggler id="hamburger1" onClick={() => this.toggleSingleCollapse("collapse1")}/>
          <MDBCollapse isOpen={this.state.collapse1} navbar>
            <MDBNavbarNav left>
              <MDBNavItem active>
                <MDBDropdown>
                  <MDBDropdownToggle nav>Home</MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/"}>Home</MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span>Explore Us</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/managementRVS"}>Management RVS</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/about"}>About</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/chairman"}>Chairman</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/principal"}>Principal</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/chairman"}>Administration</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/facilities"}>Facilities</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/committees"}>Committees</MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>

              <MDBNavItem active>
                <MDBDropdown>
                  <MDBDropdownToggle nav>Admission</MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/aboutus_01"}
                    >
                      Admission
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span>Academics</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/Project_style1"}
                    >
                      Departments
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/Project_style2"}
                    >
                      Teaching &amp; Learning
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/Project_style2"}
                    >
                      Prospectus
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span>Life at VSDC</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/Blog"}>
                      VSDC Campus
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/Blog_grid"}
                    >
                      Student Activities
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/Blog_top_image"}
                    >
                      Sports &amp; Games at VSDC
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/Blog_left_image"}
                    >
                      Facilities
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/Single_blog"}
                    >
                      Alumni VSDC
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/Single_blog"}
                    >
                      Achievers VSDC
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/Single_blog"}
                    >
                      Best Practices at VSDC
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>

              <MDBNavItem active>
                <MDBDropdown>
                  <MDBDropdownToggle nav>Gallery</MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/"}>
                      Gallery
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem active>
                <MDBDropdown>
                  <MDBDropdownToggle nav>Contact Us</MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/"}>
                      Contact Us
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </Router>
    );
  }
}

export default Mobilemenu;
