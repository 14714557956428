import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import Header from "../layout/Header";
import image1 from "../../images/Comittee/AntiRagging.jpg";
import image2 from "../../images/Comittee/Curriculum.jpg";
import image3 from "../../images/Comittee/Ethical.jpg";
import image4 from "../../images/Comittee/governance.jpg";
import image5 from "../../images/Comittee/Hostel.jpg";
import image6 from "../../images/Comittee/Library.jpg";
import image7 from "../../images/Comittee/Research.jpg";
import image8 from "../../images/Comittee/StudentNurses.jpg";
import image9 from "../../images/Comittee/student.jpg";
import image10 from "../../images/Comittee/women.jpg";

export class Committees extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row committes_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h2 className="title" style={{ color: "white" }}>Committees</h2>
                    <div className="ttm-color-home">
                      <span>
                        <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">KIN Committees</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/** women empowerment cell  */}
        <section className="ttm-row project-single-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-8 col-xl-8">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">Women Empowerment <span> Cell</span></h2>
                  </div>
                </div>
                <p className="text-justify">
                  As per the guidelines of the UGC, KIN has formed The Women
                  empowerment Cell in the year 2020. The Cell aims at providing
                  a safe studying environment for the students. It organizes
                  awareness programmes on sexual harassment. The cell provides
                  necessary support to students to face and overcome the
                  instance of harassment. The cell assists the students on
                  lodging a complaint and conducting an enquiry if any of them
                  have been a victim of sexual harassment. The cell regularly
                  organizes Guest Lectures, Seminars, Workshops, defence
                  training programmes and various awareness programmes to
                  empower women staff. Chairman Dr. Lakshmamma V.T Members • Dr.
                  Kamala J • Mrs. Santi. A
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="ttm_single_image-wrapper mt-35">
                      <img
                        className="img-fluid"
                        src={image10}
                        alt="single-img-fourteen"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="ttm-pf-single-detail-box mb-35">
                      <div className="ttm-pf-single-title">
                        <h5>Members</h5>
                      </div>
                      <ul className="ttm-pf-detailbox-list">
                        <li>
                          <i className="fa fa-user" />
                          <span> Chairman : </span> Dr. Lakshmamma V.T
                        </li>
                        <li>
                          <i className="far fa-bookmark" />
                          <span> Member :</span> Dr. Kamala J{" "}
                        </li>
                        <li>
                          <i className="fa fa-calendar" />
                          <span> Member :</span> Mrs. Santi. A
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p className="text-justify">
                    Member As per the guidelines of the UGC and Supreme Court on
                    Anti-Sexual harassment Kempegowda College Of Nursing formed
                    The Women empowerment Cell in the year 2020. The Cell aims
                    at providing a safe studying environment for the students by
                    organizing awareness programmes on sexual harassment,
                    provide necessary facilities to overcome the harassment and
                    assist the students on lodging a complaint and conducting an
                    enquiry if any of them have been a victim of sexual
                    harassment. The cell regularly organizes Guest Lectures,
                    Seminars, Workshops, defence training programme and various
                    awareness programmes to empower women staff.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-xl-4">
                <h2>OBJECTIVES</h2>
                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    To enhance confidence of women students, faculty, and staff
                    in the college.{" "}
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    To promote overall personality development of girl students.{" "}
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    To enable to make choices in areas like education, gender
                    equality, employment, and health especially reproductive
                    health & hygienic.{" "}
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    To prevent all forms of sexual harassment.
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    To create awareness of ‘The Sexual Harassment of Women at
                    Workplace (Prevention, Prohibition and Redressal) Act, 2013.
                    To encourage the early reporting of any allegations of
                    sexual harassment.
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    To ensure that the complaints will be dealt with in a
                    sensitive, equitable, timely and confidential manner.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className=" grid-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <h6>COMPLAINT AND REDRESSAL PROCEDURE</h6>
                <ul className="ttm-list ttm-list-style-icon">
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" />
                    Complaints may be made to any member of the committee, or
                    other senior members of the women cell, who will forward to
                    the committee.{" "}
                  </li>
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" />
                    After knowing grievance, discuss it with the convener of
                    women cell committee and then an appropriate solution is
                    found out.{" "}
                  </li>
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" /> The
                    committee will hear the case within 7 working days of a
                    complaint and may set up an enquiry to handle the case.
                  </li>
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" />
                    The enquiry committee will hear both from the complainant
                    and defendant, as well as any other relevant person.{" "}
                  </li>
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" />
                    The process shall be conducted to protect the well-being of
                    the parties and confidentiality of the proceedings
                  </li>
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" />
                    If not solved the committee will make recommendations to the
                    Principal and Dean, who will take appropriate action.{" "}
                  </li>
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" />
                    Appropriate safeguards will be maintained for conflict of
                    interest.
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-lg-6">
                <h6>CAMPUS POLICY AGAINST SEXUAL HARASSMENT</h6>
                <ul className="ttm-list ttm-list-style-icon">
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" /> The
                    campus is committed to provide for all female members of its
                    community who fall within its jurisdiction including its,
                    academic, non-academic staff, contractual employees and
                    labourers, and students at the place of work an environment
                    free from any form of Sexual Harassment or exploitation.
                  </li>
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" />{" "}
                    Academic staff designated for imparting
                    education/instructions or for giving guidance or rendering
                    assistance to students for pursuing any course of study,
                    whether full time, temporary, part-time, visiting, honorary,
                    consultancy, or on special duty or deputation.{" "}
                  </li>
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" /> All
                    other academic staff of other institutions visiting the
                    campus or participating in any workshop/ seminar/ training/
                    conference/consultancies is covered by these rules during
                    the stay on campus.{" "}
                  </li>
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" />
                    Non-academic Staff includes any person other than academic
                    staff, such as administrative staff, technical staff,
                    officers, consultants, support staff, contractual workers
                    and daily wagers only to the extent that they are performing
                    campus related activities.{" "}
                  </li>
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" />{" "}
                    Student includes a student of the campus, or others on
                    campus such as visiting students, and those visiting the
                    campus as a part of collaborative or exchange program
                    between institutes, students participating in
                    workshops/conferences/seminars.
                  </li>
                  <li>
                    <i className="ti-check-box ttm-textcolor-skincolor" />
                    These Rules and Procedures shall be applicable to all
                    complaints of sexual harassment provided the harassment has
                    taken place at workplace.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className=" grid-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-12">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Activities <span> </span>
                    </h2>
                  </div>
                </div>
                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                  <li>
                    <i class="fas fa-shield-alt"></i> Organized defence training
                    programme by Mr. Karthik Kateel
                  </li>
                  <li>
                    <i class="fas fa-columns"></i> Organized legal awareness
                    programme by District Legal Services Authority, Bangalore
                    Rural District.
                  </li>
                  <li>
                    <i class="fas fa-chalkboard-teacher"></i> The cell regularly
                    organizes Guest Lectures, Seminars, Workshops, and various
                    awareness programmes to empower women staff and students.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/**Anti ragging committee */}
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Anti Ragging <span> Committee</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p className="text-justify">
                      The committee holds meeting at regular intervals under the
                      patronage of the Chairman of Committee and frames the
                      rules and regulations to promote a safe, healthy and
                      conducive educative environment in college as well as in
                      college hostel and to see that campus is always ragging
                      free. Committee strives in moulding students to shoulder
                      responsibility of professionalism and to excel best in
                      their profession without indulging in any illegal ragging
                      activities.
                    </p>
                  </div>
                </div>
                <h6>Members</h6>
                <form className="ttm-cart-form ">
                  <table className="shop_table shop_table_responsive">
                    <thead className="th_color">
                      <tr>
                        <th className="product-remove"> Name of the Faculty</th>
                        <th className="product-name">Designation</th>
                        <th className="product-mainprice">Mobile Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr. V.T. Lakshmamma</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Principal</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9448809024</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs.Sunitha.V</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Warden </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9480988753</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Santhi. A </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9972752442</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Preethi</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>8050269726</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Asharani</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9902402750</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>ASI Central Police Station</h6>
                        </td>
                        <td className="product-thumbnail"></td>
                        <td className="product-name" data-title="Product"></td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image1}
                      alt="single-img-seven"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/**Governance committees */}
        <section className=" grid-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-12">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Governance <span> Committee</span>
                    </h2>
                  </div>
                </div>

                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                  <li className="text-justify">
                    <i class="fas fa-shield-alt"></i> Selection committee
                    --Direct Recruitment: The Appointing Authority if found
                    necessary to fill up any vacancy shall take steps to invite
                    application from the Eligible candidates by advertising the
                    existing vacancies in the leading newspapers in the State
                    both in Kannada and English and such other media of
                    publication as it deem fit ,by specifying the category of
                    posts, number of vacancies, conditions of eligibility and
                    method of recruitment etc.{" "}
                  </li>
                </ul>
                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                  <li>
                    <i class="fas fa-columns"></i> PROMOTION COMMITTEE--A
                    promotion committee for considering the eligibility of
                    employees for promotion consisting of the following members{" "}
                  </li>
                  <li> a.President, Rajya Vokkaligara Sangha : Chairman</li>
                  <li>b. Head of the Institution : Member </li>
                  <li>
                    c.Chief Executive Officer, Rajya Vokkaligara Sangha : Member{" "}
                  </li>
                  <li>
                    d.Administrative Officer, R.V. Sangha Head Office : Convener
                    Quorum: The quorum for the meeting shall be all the above
                    members
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row project-single-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-8 col-xl-8">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Disiciplinary <span> Committee </span>
                    </h2>
                  </div>
                </div>
                <p className="text-justify">
                  The College Discipline Committee is constituted for the
                  maintenance of discipline in the college, hospital and
                  community. The committee will ensure that students obey rules
                  and remain orderly and peaceful in the pursuant of educational
                  objectives/goals in the college, during hospital and community
                  postings.
                </p>
                <div className="row">
                  <div className="col-md-9">
                    <div className="ttm_single_image-wrapper mt-35">
                      <img
                        className="img-fluid"
                        src={image10}
                        alt="single-img-fourteen"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-xl-4">
                <div>
                  <h2>OBJECTIVES</h2>
                  <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                    <li>
                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                      To ensure calm and peaceful academic atmosphere in the
                      campus.{" "}
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                      To avoid physical confrontation among students.{" "}
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                      To conduct enquirers on report of interdisciplinary
                      activities among students{" "}
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                      To initiate model actions against students involved in
                      interdisciplinary activities.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                      To initiate steps to reduce violence, confrontation in
                      future.
                    </li>
                  </ul>
                </div>
                <h6>Members</h6>
                <form className="ttm-cart-form ">
                  <table className="shop_table shop_table_responsive">
                    <thead className="th_color">
                      <tr>
                        <th className="product-remove"> Name of the Faculty</th>
                        <th className="product-name">Designation</th>
                        <th className="product-mainprice">Mobile Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr. V.T. Lakshmamma</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Principal</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9448809024</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Kathyayini N B </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Asso.Prof </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9008035841</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Veena V </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9986659380</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mr.Mithun kumar B.P</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9902402750</h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Student Selection <span> Committee</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      It is a committee where process of sourcing, screening,
                      short listing and selecting the right students to the
                      right programme is carried out. Roles and
                      Responsibilities: • To recruit the right student to the
                      right program • Selection of students as per the criteria
                      given by apex bodies and institutional policy. • Planning
                      Orientation to the selected students on rules and
                      regulations of the college. • Explaining the rules,
                      regulations of the Institution and responsibilities of
                      particular student. • The meeting is conducted as and when
                      required.
                    </p>
                  </div>
                </div>
                <h6>Members</h6>
                <form className="ttm-cart-form ">
                  <table className="shop_table shop_table_responsive">
                    <thead className="th_color">
                      <tr>
                        <th className="product-remove"> Name of the Faculty</th>
                        <th className="product-name">Designation</th>
                        <th className="product-mainprice">Mobile Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr. V.T. Lakshmamma</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Principal</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9448809024</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr. Kamala.J </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Asso.Prof </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9980468832</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Kathyayini N B </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Asso.Prof </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9008035841</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Sushmitha</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9738157115</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mr.Mithun kumar B.P </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9902402750</h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image9}
                      alt="single-img-seven"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className=" aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image2}
                      alt="single-img-seven"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      {" "}
                      Curriculum <span> Committee</span>
                    </h2>
                  </div>
                  <div className="title-desc"></div>
                </div>
                <h6>Members</h6>
                <form className="ttm-cart-form ">
                  <table className="shop_table shop_table_responsive">
                    <thead className="th_color">
                      <tr>
                        <th className="product-remove"> Name of the Faculty</th>
                        <th className="product-name">Designation</th>
                        <th className="product-mainprice">Mobile Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr. V.T. Lakshmamma</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Principal</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9448809024</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr. Kamala.J </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Asso.Prof </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9980468832</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Kathyayini N B </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Asso.Prof </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9008035841</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Sushmitha</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9738157115</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mr.Mithun kumar B.P </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9902402750</h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Hostel <span> Committee</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p className="text-justify">
                      Hostel committee addresses the problems of the students
                      residing in the hostel, supervises and checks into the
                      hostel cleanliness and facilities of the students. Conduct
                      meetings once in two months with the representatives of
                      each batch to discuss the issues, brings the same to the
                      notice of the higher authority, decides and implement the
                      solution. The committee strives to promote the welfare of
                      the students and pupil.
                    </p>
                  </div>
                </div>
                <h6>Members</h6>
                <form className="ttm-cart-form ">
                  <table className="shop_table shop_table_responsive">
                    <thead className="th_color">
                      <tr>
                        <th className="product-remove"> Name of the Faculty</th>
                        <th className="product-name">Designation</th>
                        <th className="product-mainprice">Mobile Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr. V.T. Lakshmamma</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Principal</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9448809024</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6> Mrs.Sunitha.V </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Warden </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9480988753</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Kathyayini N B </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Asso.Prof </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9008035841</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6>Mrs. Vindhya.YH</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9731382303</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Pauline Thayavathi </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9916603625</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6>Mrs. Kavya Gowda.M </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9481900877</h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image5}
                      alt="single-img-seven"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/**Library commitee members */}
        <section className=" aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image6}
                      alt="single-img-seven"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      {" "}
                      Library Committee <span> Member</span>
                    </h2>
                  </div>
                  <div className="title-desc"></div>
                </div>
                <h6>Members</h6>
                <form className="ttm-cart-form ">
                  <table className="shop_table shop_table_responsive">
                    <thead className="th_color">
                      <tr>
                        <th className="product-remove"> Name of the Faculty</th>
                        <th className="product-name">Designation</th>
                        <th className="product-mainprice">Mobile Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr. V.T. Lakshmamma</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Principal</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9448809024</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6>Dr. Kamala.J </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Asso.Prof </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9980468832</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Kathyayini N B </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Asso.Prof </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9008035841</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6>Mrs. Santhi. A </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9972752442</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Vindhya.YH</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9731382303</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6>Mrs.Shwetha B L </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9845645489</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Jyothi L</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9916259558</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6>Mr. Mohan Kumar</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Librarian</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9480727300</h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Research and Publication <span> Committee</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p className="text-justify">
                      The committee established to encourage research and
                      publication by providing monetary support. Stimulate the
                      discussion of research ideas and methodologies based on
                      societal needs. Serve as a clearing house of information
                      about outside source of funding. Provide practical
                      criticism of research projects. Acts as an advocate for
                      the research interest of the faculties.
                    </p>
                  </div>
                </div>
                <h6>Members</h6>
                <form className="ttm-cart-form ">
                  <table className="shop_table shop_table_responsive">
                    <thead className="th_color">
                      <tr>
                        <th className="product-remove"> Name of the Faculty</th>
                        <th className="product-name">Designation</th>
                        <th className="product-mainprice">Mobile Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr. V.T. Lakshmamma</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Principal</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9448809024</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6>Dr. Kamala.J </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Asso.Prof </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9980468832</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Kathyayini N B </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Asso.Prof </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9008035841</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6>Mrs. Monali Mathad</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9620266523</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Mrs. Veena V </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9986659380</h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image7}
                      alt="single-img-seven"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className=" aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image3}
                      alt="single-img-seven"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      {" "}
                      Ethical <span> Committee</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p className="text-justify">
                      Ethical committee is a body responsible for ensuring the
                      medical experimentation and human subject researcher in an
                      ethical manner in accordance with national and
                      international law. A committee comprising of medical,
                      scientific, nonmedical and nonscientific members ,whose
                      responsibility is to ensure the protection of the rights,
                      safety and well being of human subjects involved in
                      clinical trial and it shell be responsible for reviewing.
                      The major responsibility of ethical committee is to
                      protect the rights, safety, and well being of the research
                      participants. The ethical committee conducts regular
                      meeting for reviewing the research proposals and give
                      suggestions to the investigators to make their research
                      ethical before approving them all research projects.
                    </p>
                  </div>
                </div>
                <h6>Members</h6>
                <form className="ttm-cart-form ">
                  <table className="shop_table shop_table_responsive">
                    <thead className="th_color">
                      <tr>
                        <th className="product-remove"> Name of the Faculty</th>
                        <th className="product-name">Designation</th>
                        <th className="product-mainprice">Mobile Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr. V.T. Lakshmamma</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Principal</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9448809024</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6>Mrs. Sudha. </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Principal </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6></h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr.Anil kumar </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Professor </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6></h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6>Dr. Tejasvi </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Assistant professor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6></h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr. Gangaboraiah</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> professor</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6></h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      {" "}
                      Student Nurses <span> Association</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p className="text-justify">
                      Student Nurses Association is a Nation-wide affiliated
                      organization of Nursing Students. The main purpose of
                      forming the association at Kempegowda Institute of Nursing
                      was to provide conceit to nursing students to enable them
                      for all round development. Through SNA students are
                      encouraged to take part in various cultural and sports
                      competitions at various levels like collegiate, State and
                      National. This will enable them for overall development of
                      personality and to showcase their talent in various other
                      sectors along with academics activities. SNA will also
                      motivate students to develop leadership abilities, and to
                      improve the social contacts to enhance professional growth
                      and also learn professional responsibility. With all the
                      above SNA unit at our college is been an important source
                      of TNAI membership.{" "}
                    </p>
                  </div>
                </div>
                <h6>Members</h6>
                <form className="ttm-cart-form ">
                  <table className="shop_table shop_table_responsive">
                    <thead className="th_color">
                      <tr>
                        <th className="product-remove"> Name of the Faculty</th>
                        <th className="product-name">Designation</th>
                        <th className="product-mainprice">Mobile Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Dr. V.T. Lakshmamma</h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Principal</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>9448809024</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6>Mrs. Preethi </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> Nursing tutor </h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6>8050269726</h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Sneha Das </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6>student</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6></h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_white">
                        <td className="product-remove">
                          <h6>Dishani misya </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6>student</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6></h6>
                        </td>
                      </tr>
                      <tr className="cart_item table-bgcolor_blue">
                        <td className="product-remove">
                          <h6>Piyasa Achanjya </h6>
                        </td>
                        <td className="product-thumbnail">
                          <h6> student</h6>
                        </td>
                        <td className="product-name" data-title="Product">
                          <h6></h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image8}
                      alt="single-img-seven"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Committees;
