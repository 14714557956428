import React, { Component } from "react";
import CountUp from "react-countup";
import Header from "../layout/Header";
import image from "../../images/image11.jpg";
import image1 from "../../images/kinimg.jpg";
import { getDocs, collection } from "firebase/firestore";
import firestore from "../../Config/Config";

export class AboutKIN extends Component {

  constructor(props) {
    super(props);

    this.dynamicData = { KINnumbers: [] };
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) { arr.push(docData[key]); }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "Home");
    const kinnumberkey = "KINnumbers";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      const allDynamicData = this.getDynamicData(Snapshot);
      this.dynamicData.KINnumbers = allDynamicData[kinnumberkey];
    }
    const arr = [];
    this.setState({ KINnumbers: arr });
  }
  
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row aboutbanner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>ABOUT KIN</h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">RVS</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="ttm-row  bottomzero-padding-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="position-relative">
                  <div className="ttm_single_image-wrapper text-sm-center mr-35 res-991-m-0">
                    <img className="img-fluid-height" src={image} title="single-img-nine" alt="single-img-nine" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="res-991-mt-30">
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">ABOUT <span> KIN</span></h2></div>
                    <div className="title-desc">
                      <p className="text-justify">
                        Kempegowda Institute of Nursing (KIN) is a premier
                        institute offering quality nursing education.
                        Established in 1990, the institute has more than 30
                        years of excellence in professional nursing education.
                        Institute is affiliated to Rajiv Gandhi University of
                        Health Sciences [RGUHS], Karnataka and recognized by the
                        Indian Nursing Council and the Karnataka Nursing
                        Council. KIN offers Diploma in Nursing (GNM), B Sc.
                        Nursing, PB B Sc Nursing, M Sc Nursing, and Nurse
                        Practitioner in Critical Care courses. The institute is
                        located in the heart of Garden city, Bengaluru with all
                        required facilities. It is a minute’s walk from the bus
                        terminus, metro station and is easily accessible from
                        every part of the city. Nursing profession is a noble
                        profession. A professional nurse plays a unique role in
                        society, in promoting health, prevention of illness and
                        in giving care, love and affection to patients. Nursing
                        training programs in KIN have been formulated to groom
                        professional competent nurses. A team of well Qualified
                        and experienced full-time faculty work with dedication
                        to provide the right mix of inputs of theoretical,
                        practical and clinical experience to nursing students. KIN is attached to the
                        1100-bed multi-specialty KIMS hospital where students
                        get hands-on-training and rich clinical exposure in all
                        fields of nursing care.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ttm-row aboutus-section-style3 clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="res-991-mt-30">
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">ABOUT <span> KEMPEGOWDA HOSPITAL</span></h2>
                    </div>
                    <div className="title-desc">
                      <p className="text-justify">
                        The Kempegowda hospital was established in the year 1990
                        which fulfills Rajya Vokkaligara Sangha’s commitment
                        towards two core areas of society, education and health.
                        Kempegowda hospital is located in the heart of Bengaluru
                        city very close to K.R.Market and Metro station. It is a
                        multispecialty hospital having 1100 beds offering
                        services in 15+ broad general specialities including
                        Medical and Allied, Surgical and Allied and OBG. The
                        Super Specialties include Urology, Medical Oncology,
                        Surgical Oncology, Plastic Surgery, Neuro Surgery,
                        Pediatric Surgery, Vascular Surgery and Surgical
                        Gastroenterology. For cardiology, Kempegowda hospital
                        has a well-equipped KIMS heart centre. Kempegowda
                        hospital has all kinds of health infrastructural
                        facilities where nursing students can get
                        hands-on-training and rich clinical exposure in all
                        fields of nursing care.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="position-relative">
                  <div className="ttm_single_image-wrapper text-sm-center mr-35 res-991-m-0">
                    <img className="img-fluid-height" src={image1} title="single-img-nine" alt="single-img-nine" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" clearfix" style={{ marginTop: "-175px" }}>
          <div className="container-xl">
            <div className="row no-gutters mt_100 res-991-mt-0">
              <div className="col-md-6">
                <div className="ttm-bgcolor-yellow ttm-textcolor-white z-11 position-relative h-100">
                  <div className="layer-content">
                    <div className="spacing-2">
                      <div className="section-title ttm with-desc clearfix">
                        <div className="title-header"><h2 className="title">Vision</h2></div>
                      </div>
                      <div className="ttm-progress-bar">
                        <div className="progress-bar-title float-left">
                          To establish into a distinguished nursing institution
                          offering competency based education combining sound
                          scientific knowledge, pertinent technology and ethical
                          values with high level of empathetic clinical care
                          practices.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-aqua ttm-textcolor-white">
                  <div className="section-title ttm with-desc clearfix">
                    <div className="spacing-2">
                      <div className="title-header">
                        <h2 className="title " style={{ color: "#eadb4f" }}>Mission</h2>
                      </div>
                      <div>
                        <ul>
                          <li>To promote quality education and professional competencies.</li>
                          <li>To indulge in relevant research practices.</li>
                          <li>To nurture commitment towards accountable patient care.</li>
                          <li>To encourage community services with societal engagement.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="ttm-row bottomzero-padding-section bg-img-lib position-relative z-1 clearfix">
          <div className="container-xl">
            <div className="row no-gutters">
              <div className="col-lg-10 mb_80 res-991-mb-0">
                <div className="ttm-bgcolor-aqua ttm-textcolor-white">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ marginLeft: "-400px", opacity: "1" }}>
                    <div className="ttm-bg-layer-inner"></div>
                  </div>
                  <div className="layer-content">
                    <div className="spacing-5">
                      <div className="section-title mb-4 clearfix">
                        <div className="title-header">
                          <h2 className="title header">WELL EQUIPPED STATE OF <span>ART LIBRARY</span></h2>
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-md-4">
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon"><div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-24h" /></div></div>
                            <div className="featured-content">
                              <div className="featured-title"><h5 className="fw-500 header">Journal-48</h5></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon"><div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-data" /></div></div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h5 className="fw-500 header">International-33</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon"><div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-code" /></div></div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h5 className="fw-500 header">National - 15</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-md-4">
                          <div className="featured-icon-box style4 left-icon"><div className="featured-icon"><div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-24h" /></div></div>
                            <div className="featured-content">
                              <div className="featured-title"><h5 className="fw-500 header">Books-21500</h5></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon"><div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-data" /></div></div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h5 className="fw-500 header">Periodicals - 145</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon"><div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-code" /></div></div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h5 className="fw-500 header">Audio Visual Materials </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-md-4">
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon"><div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-24h" /></div></div>
                            <div className="featured-content">
                              <div className="featured-title"><h5 className="fw-500 header">Kannada Novel and Text Books - 500</h5></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon"><div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-data" /></div></div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h5 className="fw-500 header">Microfilm Reading, LCD Progector Room</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon"><div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-code" /></div></div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h5 className="fw-500 header">WHO Publication since 1981</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-md-4">
                          <div className="featured-icon-box style4 left-icon"><div className="featured-icon"><div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-24h" /></div></div>
                            <div className="featured-content">
                              <div className="featured-title"><h5 className="fw-500 header">Dissertation - 380</h5></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon"><div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-data" /></div></div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h5 className="fw-500 header">Audio Cassettes </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon">
                              <div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-code" /></div>
                            </div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h5 className="fw-500 header">Bound Volume - 10150</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-md-12">
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon">
                              <div className="ttm-icon ttm-icon_element-size-md"><i className="flaticon flaticon-24h" /></div>
                            </div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h5 className="fw-500 header">RGUHS Publications - 510</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ttm-row fid-section ttm-bgcolor-yellow ttm-bg ttm-bgimage-yes mt_225 res-991-mt-0 clearfix">
          <br/>
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="section-title with-desc text-center clearfix">
              <div className="title-header"><h2 className="title">KIN IN <span style={{color:"white"}}> NUMBERS</span></h2></div>
            </div>
            {this.dynamicData.KINnumbers.length &&
              this.dynamicData.KINnumbers.map(function (item, index) {
                return (
                  <div className="row" key={index}>
                    <div className="col-md-3 col-6">
                      <div className="ttm-fid inside ttm-fid-view-topicon">
                        <div className="ttm-fid-icon-wrapper">
                          <div className="ttm-icon ttm-bgcolor-aqua ttm-icon_element-size-lg">
                            <i className="flaticon flaticon-online-library header" />
                          </div>
                        </div>
                        <div className="ttm-fid-contents">
                          <h4><CountUp start={0} end={item.Student} duration={30}/></h4>
                          <h3 className="ttm-fid-title">No of Students</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="ttm-fid inside ttm-fid-view-topicon">
                        <div className="ttm-fid-icon-wrapper">
                          <div className="ttm-icon ttm-bgcolor-aqua ttm-icon_element-size-lg">
                            <i className="flaticon flaticon-developer header" />
                          </div>
                        </div>
                        <div className="ttm-fid-contents">
                          <h4><CountUp start={0} end={item.PhD} duration={30} /></h4>
                          <h3 className="ttm-fid-title">Number of Ph.D</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="ttm-fid inside ttm-fid-view-topicon">
                        <div className="ttm-fid-icon-wrapper">
                          <div className="ttm-icon ttm-bgcolor-aqua ttm-icon_element-size-lg">
                            <i className="flaticon flaticon-24h header" />
                          </div>
                        </div>
                        <div className="ttm-fid-contents">
                          <h4><CountUp start={0} end={item.TeachingFaculties} duration={30}/></h4>
                          <h3 className="ttm-fid-title">Number of Teaching Faculty</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="ttm-fid inside ttm-fid-view-topicon">
                        <div className="ttm-fid-icon-wrapper">
                          <div className="ttm-icon ttm-bgcolor-aqua ttm-icon_element-size-lg">
                            <i className="flaticon flaticon-report header" />
                          </div>
                        </div>
                        <div className="ttm-fid-contents">
                          <h4><CountUp start={0} end={item.NonTeachingFaculty} duration={30}/></h4>
                          <h3 className="ttm-fid-title">Number of Non Teaching Faculty</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </section>
      </div>
    );
  }
}

export default AboutKIN;
