import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Header from "../layout/Header";
import { doc, getDoc } from 'firebase/firestore';
import firestore from "../../Config/Config";

export class managementRVS extends Component {

  state = {
    PresidentName: "",
    PresidentDesignation: "",
    PresidentImage: "",
    MainDatas: [],
    ECDatas: [],
    GCDatas: [],
    Loading: true
  }

  //Load Data Here
  async componentDidMount() {

    //Get President Data
    const docRef = doc(firestore, "Others", "RVSPresident");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        this.setState({
          PresidentName: value.Name,
          PresidentDesignation: value.Designation,
          PresidentImage: value.Image,
        })
      });
    }

    //Get Main Member Data
    const docRef_1 = doc(firestore, "Others", "MainMembers");
    const docSnap_1 = await getDoc(docRef_1);
    var Data_1 = []
    if (docSnap_1.exists()) {
      Object.entries(docSnap_1.data()).forEach(([key, value]) => {
        Data_1.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });

      Data_1.length === 0 ?
        this.setState({ MainDatas: "NO_999", Loading: false }) :
        this.setState({ MainDatas: Data_1.sort(function (a, b) { return a.Priority - b.Priority; }), Loading: false })
    }

    //Get EC Member Data
    const docRef_2 = doc(firestore, "Others", "ECMembers");
    const docSnap_2 = await getDoc(docRef_2);
    var Data_2 = []
    if (docSnap_2.exists()) {
      Object.entries(docSnap_2.data()).forEach(([key, value]) => {
        Data_2.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });

      Data_1.length === 0 ?
        this.setState({ ECDatas: "NO_999", Loading: false }) :
        this.setState({ ECDatas: Data_2.sort(function (a, b) { return a.Priority - b.Priority; }), Loading: false })
    }

    //Get GC Member Data
    const docRef_3 = doc(firestore, "Others", "GCMembers");
    const docSnap_3 = await getDoc(docRef_3);
    var Data_3 = []
    if (docSnap_3.exists()) {
      Object.entries(docSnap_3.data()).forEach(([key, value]) => {
        Data_3.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });

      Data_3.length === 0 ?
        this.setState({ GCDatas: "NO_999", Loading: false }) :
        this.setState({ GCDatas: Data_3.sort(function (a, b) { return a.Priority - b.Priority; }), Loading: false })
    }

  }

  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row explorebanner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>Management RVS</h4>
                    <div className="ttm-color-home">
                      <span><a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}><i className="ti ti-home" />&nbsp;&nbsp;Home</a></span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>RVS</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className=" grid-section clearfix">
          <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-lg-9 content-area order-lg-2">
                  <div className="ttm-service-single-content-area">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header">
                        <h2 className="title">Rajya Vokkaliga Sangha <span>(RVS)</span></h2>
                      </div>
                    </div>
                    <div className="ttm-service-description">
                      <h5>HISTORY</h5>
                      <p className="text-justify">
                        Rajya Vokkaligara Sangha (RVS) was established in the
                        year 1906 to promote social, cultural and educational
                        aspirations of the agricultural community. Rajya
                        Vokkaligara Sangha with a commanding presence in
                        Bengaluru, Ramanagara, Mandya, Mysuru, Chikmagalur,
                        Hassan, Tumukuru, Chikkaballapura, Kolar and Chitradurga
                        districts and with a fairly well spread presence in
                        other regions of Karnataka, has been conducting number
                        of regular activities for the development and wellbeing
                        of the community in particular and to all sections of
                        the society in general. The sangha has also provided
                        hostel facilities for poor economic background students
                        and students are making use of these hostel facilities
                        during their education career.
                      </p>
                      <h5>Objectives</h5>
                      <p>1. To promote educational aspirations of the community by starting educational institutions in various disciples.</p>
                      <p>2. To encourage, promote and popularize cultural activities.</p>
                      <p>3. To promote well-being community in all sections of society.</p>
                      <p>4. To improve the living of economically poor community students by setting up free hostel facilities.</p>
                      <div className="separator"><div className="sep-line mt-35 mb-35 res-991-mtb-20" /></div>
                      <div className="ttm-service-single-content-area">
                        <div className="section-title with-desc text-left clearfix paddingtop-2">
                          <div className="title-header" style={{ borderTopColor: "red" }}>
                            <h4 className="title">Organizational Structure</h4>
                          </div>
                        </div>
                        <div className="row ttm-service-description" style={{ textAlign: "center" }}>
                          <div className='col-md-6 offset-md-3 paddingtop-1'>
                            <img className="img-fluid" src={this.state.PresidentImage} alt="error" />
                            <h5 className="paddingtop-1">{this.state.PresidentName}</h5>
                            <h6>{this.state.PresidentDesignation}</h6>
                          </div>
                          {
                            this.state.MainDatas.map((data, i) => (
                              <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }} key={i}>
                                <img className="img-fluid" src={data.Image} alt="error" />
                                <h6 className="paddingtop-1">{data.Name}</h6>
                                <small>{data.Designation}</small>
                              </div>
                            ))
                          }
                        </div>
                        <div class="ttm-service-single-content-area">
                          <div className="section-title with-desc text-left clearfix paddingtop-2">
                            <div className="title-header" style={{ borderTopColor: "red" }}>
                              <h4 className="title">Executive Council Members</h4>
                            </div>
                          </div>
                          <div className="row ttm-service-description" style={{ textAlign: "center" }}>
                            {
                              this.state.ECDatas.map((data, i) => (
                                <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                                  <img className="img-fluid" src={data.Image} alt="error" />
                                  <h6 className="paddingtop-1">{data.Name}</h6>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                        <div class="ttm-service-single-content-area">
                          <div className="section-title with-desc text-left clearfix paddingtop-2">
                            <div className="title-header" style={{ borderTopColor: "red" }}>
                              <h4 className="title">Governing Council Members</h4>
                            </div>
                          </div>
                          <div className="row ttm-service-description" style={{ textAlign: "center" }}>
                            {
                              this.state.GCDatas.map((data, i) => (
                                <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                                  <img className="img-fluid" src={data.Image} alt="error" />
                                  <h6 className="paddingtop-1">{data.Name}</h6>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                        <br /><br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 widget-area">
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default managementRVS;
