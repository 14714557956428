import React, { Component } from "react";
import Slider from "react-slick";
import { MDBProgress } from "mdbreact";
import Header from "./layout/Header";
import { CarouselPage } from "./banner/Home1_banner";
import { getDocs, collection } from "firebase/firestore";
import firestore from "../Config/Config";
import "react-image-lightbox/style.css";
import image2 from "../images/Highlightsimages/image1.jpg";
import image3 from "../images/Highlightsimages/image2.jpg";
import image4 from "./../images/Highlightsimages/image3.jpg";
import image6 from "./../images/Highlightsimages/image5.jpg";
import image7 from "../images/Highlightsimages/image6.jpg";
import image8 from "../images/Highlightsimages/image7.jpg";
import image12 from "../images/Highlightsimages/image12.jpg";
import prospectus from "../assets/Admission/Collegebroucher.pdf";
import recuiter1 from "../images/Recuiters/image1.jpg";
import recuiter2 from "../images/Recuiters/image2.jpg";
import recuiter3 from "../images/Recuiters/image3.jpg";
import recuiter4 from "../images/Recuiters/image4.jpg";
import recuiter5 from "../images/Recuiters/image5.jpg";
import NoData from "./NoData";

export class Home extends Component {

  constructor(props) {
    super(props);

    this.dynamicData = {
      notifications: [],
      events: [],
      prospectus: {},
      facultyAcheivers: [],
      studentAcheivers: [],
      Recruiters: [],
      KINnumbers: [],
      Testimonial: [],
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "Home");
    const notificationKey = "Notifications";
    const eventsKey = "Events";
    const facAchKey = "FacultyAchievers";
    const stuAchKey = "StudentAchievers";
    const recruiterKey = "Recruiters";
    const kinkey = "KINnumbers";
    const testimonial = "Testimonial"

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      const allDynamicData = this.getDynamicData(Snapshot);
      this.dynamicData.notifications = allDynamicData[notificationKey];
      this.dynamicData.events = allDynamicData[eventsKey];
      this.dynamicData.facultyAcheivers = allDynamicData[facAchKey];
      this.dynamicData.studentAcheivers = allDynamicData[stuAchKey];
      this.dynamicData.Recruiters = allDynamicData[recruiterKey];
      this.dynamicData.KINnumbers = allDynamicData[kinkey];
      this.dynamicData.Testimonial = allDynamicData[testimonial];
    }

    this.setState({});
  }

  render() {

    var slick_slider = {
      dots: true,
      arrow: true,
      autoplay: true,
      autoplaySpeed: 2500,
      speed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="site-main">

        {/*Home Header */}
        <Header />

        {/*Home Banner */}
        <CarouselPage />

        {/*Notification KIN*/}
        <section className="ttm-row service-section position-relative z-1 clearfix">
          <div className="container xl">
            <Slider className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-yellow" {...slick_slider}>
              {this.dynamicData.notifications === undefined ? (<NoData />) : (
                this.dynamicData.notifications.length &&
                this.dynamicData.notifications.map(function (item, index) {
                  return (
                    <div key={index}>
                      <div className={`featured-icon-box left-icon style1 ttm-textcolor-white h-100 ${index % 2 === 0 ? "ttm-bgcolor-skincolor" : ""}`}>
                        <div className="featured-icon">
                          <div className={`ttm-icon ttm-icon_element-size-md ${index % 2 === 0 ? "ttm-bgcolor-darkgrey" : "ttm-bgcolor-skincolor "}`}>
                            <i className="flaticon flaticon-system" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-desc">{item.Header}</div>
                          {item.Content !== "undefined" && item.Content.length && item.Content !== " " ? (
                            <a href={item.Content} target="_blank" style={{ textDecoration: "underline" }}>Click Here</a>
                          ) : ("")}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </section>

        {/*HIGHLIGHTS KIN*/}
        <section className="ttm-row process-section clearfix">
          <div className="container-xl">
            <div className="section-title text-center with-desc clearfix">
              <div className="title-header"><h2 className="title">HIGHLIGHTS</h2></div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-processbox-wrapper">
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img className="img-fluid" alt="step-one" title="step-one" src={image2} />
                      <div className="process-num"><span className="number">01</span></div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title">
                        <h5><a href="AboutVSDC#highlights">LOCATION ADVANTAGE</a></h5>
                      </div>
                      <div className="featured-desc text-justify">
                        <p>
                          Located in the Heart of the City, seamless
                          connectivity to all major public transportation
                          hubs, namely - Railway Station, Bus stand and Metro
                          Railway Station. Close Proximity to all necessary
                          convenieces and other social infrastucture
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img className="img-fluid" alt="step-two" title="step-two" src={image3} />
                      <div className="process-num"><span className="number">02</span></div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title">
                        <h5>RICH CLINICAL EXPOSURE</h5>
                      </div>
                      <div className="ttm-box-description text-justify">
                        Student will be posted in KIMS throughout the year in
                        all 3 shifts because of the presence of varied
                        multispeciality departments, namely - Paediatric, OBG, Medical Surgical, Psychiatric & Community Medicine.
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img className="img-fluid" alt="step-three" title="step-three" src={image4} />
                      <div className="process-num"><span className="number">03</span></div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title"><h5>100% PLACEMENT</h5></div>
                      <div className="ttm-box-description text-justify">
                        Students are well trained on competency based education. Students are placed in full in reputed private health care sectors specifically,
                        Fortis, TATA Medical Center, Columbia Asia, BGS, Manipal & in Institutions of National importance namely NIMHANS, Jayadev Hospitals.
                        Students are Offered by Very good packages and perks.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-processbox-wrapper">
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img className="img-fluid" alt="step-one" title="step-one" src={image6} />
                      <div className="process-num"><span className="number">04</span></div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title"><h5>GLOBAL ALUMNI CONNECT</h5></div>
                      <div className="ttm-box-description text-justify">
                        Students are working in USA, UK, Australia, Kuwait, Saudi Arabia, Muscat. Students hold a laudable positions namely, Intensivisit, Head of resource management and liaison,
                        Registered Nurse, Clinical Site Manager, Clinical Facilitator, Unit Manager.
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img className="img-fluid" alt="step-two" title="step-two" src={image7} />
                      <div className="process-num"><span className="number">05</span></div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title"><h5>Hygeinic Hostel And Welfare Measures</h5></div>
                      <div className="ttm-box-description text-justify">
                        Well facilitated & secured hostel for girl students. Pest control measures are ensured for the good health of students. Installation of good quality Incinerator.
                        On-campus accommodation is available to out-station students.
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img className="img-fluid" alt="step-three" title="step-three" src={image8} />
                      <div className="process-num"><span className="number">06</span></div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title"><h5>Qualified and Experieced Faculty</h5></div>
                      <div className="ttm-box-description text-justify">
                        KIN has team of well experienced and highly qualified faculty members. The faculty encourages and inspires the students to put in their best and excel in their fields.
                        The faculty strives hard to provide them all round knowledge in theory and practical work and guides them in all other academic activities like seminars,
                        projects, training etc,. Success of KIN can be directly attributed to the dedication and high caliber of our faculty.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Walkthrough KIN*/}
        <section className="ttm-row first-row-title-section ttm-bgcolor-yellow ttm-textcolor-white ttm-bg ttm-bgimage-yes walkthrough-bg-img clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="text-center">
                  <div className="ttm-play-icon-btn mb-5">
                    <div className="ttm-play-icon-animation">
                      <a href="https://www.youtube.com/embed/VtKGQ4NSwFE" target="_blank">
                        <div className="ttm-icon ttm-bgcolor-white ttm-textcolor-skincolor ttm-icon_element-size-sm rounded-circle"><i className="fas fa-play" /></div>
                      </a>
                    </div>
                  </div>
                  <div className="section-title with-desc text-center clearfix">
                    <div className="title-header"><h2 className="title">WALKTHROUGH KIN</h2></div>
                    <div className="title-desc ">
                      <h6 style={{ color: "white", wordSpacing: "10px", lineHeight: "25px" }}>
                        Kempegowda Institute of Nursing was established in the
                        year1990 and is a Premier nursing institute in Bangalore
                        offering Diploma in Nursing (GNM), B Sc. (Nursing), PB
                        BSc (Nursing) and M Sc (Nursing) Courses and Nurse
                        practitioner in critical care.
                      </h6>
                    </div>
                  </div>
                  <div className="mt-5">
                    <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white ttm-bgcolor-aqua" href={prospectus} target="_blank" style={{ backgroundColor: "#009c9f" }}>KIN Prospectus</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Kims Section */}
        <div className="container-xl" style={{ paddingTop: "3rem" }}>
          <div className="row">
            <div className="col-lg-3">
              <div className="col-bg-img-three-kims ttm-bg ttm-col-bgimage-yes res-991-h-auto">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                  <div className="ttm-bg-layer-inner" />
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <h3 className="mb-0">Kempegowda Institute of Nursing is Attached to Kempegowda Institute of Medical Science and Research Centre</h3><br />
              <p className="mb-0">
                The Kempegowda hospital was established in the year 1990 which fulfills Rajya Vokkaligara Sangha’s commitment towards two core areas of society, i.e, Education and Health.
                Kempegowda hospital is located in the heart of Bengaluru city very close to K.R.Market and Metro station. It is a multispecialty hospital having 1100 beds offering
                services in 15+ broad general specialities including Medical and Allied, Surgical and Allied and OBG. The Super Specialties include Urology, Medical Oncology,
                Surgical Oncology, Plastic Surgery, Neuro Surgery, Pediatric Surgery, Vascular Surgery and Surgical Gastroenterology. For cardiology, Kempegowda hospital has a well-equipped KIMS heart centre.
              </p>
              <p>Kempegowda Institute of nursing is attached to Kempegowda Institute of medical Science and Research Centre which has all kinds of health infrastructural facilities where nursing students can get hands-on-training and rich clinical exposure in all fields of nursing care.</p>
            </div>
          </div>
        </div>

        {/* Kim Recruiters Dynamic*/}
        <section className=" bottomzero-padding-section position-relative clearfix" style={{ paddingTop: "50px" }}>
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">KIN <span> Recruiters</span></h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row multi-columns-row ttm-boxes-spacing-10px ttm-bgcolor-white">
              {this.dynamicData.Recruiters === undefined ? (<NoData />) : (
                this.dynamicData.Recruiters.length &&
                this.dynamicData.Recruiters.map(function (item, index) {
                  return (
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6" key={index}>
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        <div className="featured-thumbnail">
                          <img className="img-fluid" src={item.Image} alt="image" />
                        </div>
                        <div className="featured-content">
                          <div className="category">
                            <p>{item.Description}</p>
                          </div>
                          <div className="featured-title">
                            <h5 className="header">{item.Name}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </section>

        {/* Kim Recruiters Static*/}
        <section className="ttm-row our-partner-section ttm-bgcolor-yellow mt_90 res-991-mt-0 res-991-p-0 clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-12 text-center">
                <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={5}>
                  <div className="ttm-box-col-wrapper">
                    <div className="client-box ttm-box-view-boxed-logo">
                      <div className="client">
                        <div className="ttm-client-logo-tooltip" data-tooltip="120 students">
                          <img className="img-fluid" src={recuiter1} alt="image" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="client-box ttm-box-view-boxed-logo">
                      <div className="client">
                        <div className="ttm-client-logo-tooltip" data-tooltip="130 students">
                          <img className="img-fluid" src={recuiter2} alt="image" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="client-box ttm-box-view-boxed-logo">
                      <div className="client">
                        <div className="ttm-client-logo-tooltip" data-tooltip="230 students" >
                          <img className="img-fluid" src={recuiter3} alt="image" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="client-box ttm-box-view-boxed-logo">
                      <div className="client">
                        <div className="ttm-client-logo-tooltip" data-tooltip="170 students">
                          <img className="img-fluid" src={recuiter4} alt="image" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="client-box ttm-box-view-boxed-logo">
                      <div className="client">
                        <div className="ttm-client-logo-tooltip" data-tooltip="130 students">
                          <img className="img-fluid" src={recuiter5} alt="image" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonial section */}
        <section className="ttm-row bottomzero-padding-section ttm-bgcolor-grey ttm-bg ttm-bgimage-yes bg-img8 clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="section-title text-center with-desc clearfix">
              <div className="title-header">
                <h2 className="title">TESTIMONIAL</h2>
              </div>
            </div>
            <div className="row">
              {this.dynamicData.Testimonial === undefined ? (<NoData />) : (
                this.dynamicData.Testimonial.length &&
                this.dynamicData.Testimonial.map(function (item, index) {
                  return (
                    <div className="col-md-3 card" style={{ marginBottom: "1rem" }} key={index}>
                      <object data={item.Embbed}></object>
                      <div class="card-body">
                        <h5 class="card-title">{item.Name}</h5>
                        <p class="card-text">{item.Description}</p>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </section>

        {/* KIN IN Numbers*/}
        <section className="ttm-row clearfix" style={{ paddingTop: "200px" }}>
          <div className="container-xl">
            <div className="row no-gutters mt_100 res-991-mt-0 d-flex">
              {this.dynamicData.KINnumbers === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.KINnumbers.length &&
                this.dynamicData.KINnumbers.map(function (item, index) {
                  return (
                    <div className="col-md-6" key={index}>
                      <div className="ttm-bgcolor-yellow ttm-textcolor-white z-11 position-relative h-100">
                        <div className="layer-content">
                          <div className="spacing-2">
                            <div className="section-title ttm with-desc clearfix">
                              <div className="title-header">
                                <h2 className="title">
                                  KIN In{" "}
                                  <span className="ttm-textcolor-white">
                                    Numbers
                                  </span>
                                </h2>
                              </div>
                            </div>
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                No of Students
                              </div>
                              <label className="float-right">
                                {item.Student}
                              </label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={70}
                              ></MDBProgress>
                            </div>
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                Number of PHD
                              </div>
                              <label className="float-right">{item.PhD}</label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={80}
                              ></MDBProgress>
                            </div>
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                Number of Teaching Faculty
                              </div>
                              <label className="float-right">
                                {item.TeachingFaculties}
                              </label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={80}
                              ></MDBProgress>
                            </div>
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                Number of Non Teaching Faculty
                              </div>
                              <label className="float-right">
                                {item.NonTeachingFaculty}
                              </label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={80}
                              ></MDBProgress>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}

              <div className="col-md-6 ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-aqua ttm-textcolor-white">
                <div className="col-bg-img-two  ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-aqua ttm-textcolor-white">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-bg-layer-inner" />
                  </div>
                  <div className="layer-content">
                    <div className="testimonial-slide style1">
                      <div className="col-lg-12">
                        <div className="testimonials text-center">
                          <div className="testimonial-content">
                            <div className="testimonial-avatar">
                              <div className="testimonial-img">
                                <img
                                  className="img-center"
                                  alt="testimonial-img"
                                  src={image12}
                                />
                              </div>
                            </div>
                            <blockquote>
                              Florence Nightingale (1820-1910), known as “The
                              Lady with the Lamp,” was a British nurse, social
                              reformer and statistician best known as the
                              founder of modern nursing. Her experiences as a
                              nurse during the Crimean War were foundational in
                              her views about sanitation.
                            </blockquote>
                            <br />
                            <div className="testimonial-caption">
                              <h6>Florence Nightingale</h6>
                              <label>Founder of modern nursing</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Events */}
        <section className=" history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix" style={{ paddingBottom: "70px", paddingTop: "50px" }}>
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header"><h2 className="title">News and <span>Events</span></h2></div>
                </div>
              </div>
            </div>

            <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider}>
              {this.dynamicData.events === undefined ? (<NoData />) : (
                this.dynamicData.events.length &&
                this.dynamicData.events.map(function (item, index) {
                  return (
                    <div className=" row ttm-box-col-wrapper " key={index}>
                      <div className="ttm-history-box-wrapper">
                        <div className="ttm-history-box-icon-wrapper">
                          <div className="featured-icon-box">
                            <div className="featured-icon">
                              <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md " style={{ width: "150px" }}>
                                <img src={item.Image} alt="col-bgimage-1" style={{ width: "150px", height: "140px" }} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-history-box-border" style={{ margin: "49px 0px 43px -5px" }} />
                        <div className="ttm-history-box-details ttm-bgcolor-white" style={{ minHeight: "230px" }}>
                          <div className="ttm-historybox-title"><h5>{item.Header}</h5></div>
                          <div className="ttm-historybox-description text-justify">{item.Description}</div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </section>

        {/* Faculty Acheivers */}
        <section className=" blog-section res-991-pt-0 clearfix" style={{ paddingTop: "50px" }}>
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">FACULTY <span>ACHEIVERS</span></h2>
                  </div>
                </div>
              </div>
            </div>
            <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={3}>
              {this.dynamicData.facultyAcheivers === undefined ? (<NoData />) : (
                this.dynamicData.facultyAcheivers.length &&
                this.dynamicData.facultyAcheivers.map(function (item, index) {
                  return (
                    <div className="ttm-box-col-wrapper d-block" key={index}>
                      <div className="featured-imagebox featured-imagebox-blog">
                        <div className="featured-thumbnail">
                          <img className="img-fluid" alt="" src={item.Image} style={{ width: "360px", height: "260px" }} />
                        </div>
                        <div className="featured-content">
                          <div className="ttm-box-post-date">
                            <span className="ttm-entry-date">
                              <time className="entry-date" dateTime="2019-01-16T07:07:55+00:00">{item.Year}</time>
                            </span>
                          </div>
                          <div className="featured-title"><h5>{item.Name}</h5><small>{item.Department}</small></div>
                          <hr />
                          <p style={{ textOverflow: "ellipsis", overflow: 'hidden', maxHeight: "4.3rem", minHeight: "4.3rem" }}>{item.Description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </section>

        {/* Students Acheivers */}
        <section className=" blog-section res-991-pt-0 clearfix" style={{ paddingTop: "50px" }}>
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">UNIVERSITY RANK <span>HOLDERS</span></h2>
                  </div>
                </div>
              </div>
            </div>
            <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={3}>
              {this.dynamicData.studentAcheivers === undefined ? (<NoData />) : (
                this.dynamicData.studentAcheivers.length &&
                this.dynamicData.studentAcheivers.map(function (item, index) {
                  return (
                    <div className="ttm-box-col-wrapper h-100" key={index}>
                      <div className="featured-imagebox featured-imagebox-blog">
                        <div className="featured-thumbnail"><img className="img-fluid" alt="" src={item.Image} style={{ width: "360px", height: "260px" }} /></div>
                        <div className="featured-content">
                          <div className="ttm-box-post-date">
                            <span className="ttm-entry-date">
                              <time className="entry-date" dateTime="2019-01-16T07:07:55+00:00">
                                <span className="entry-month entry-year">{item.Year}</span>
                              </time>
                            </span>
                          </div>
                          <div className="featured-title"><h5>{item.Name}</h5></div>
                          <div className="post-meta">
                            <span className="ttm-meta-line"><i className="fa fa-graduation-cap" /> {item.Achievement}</span>
                            <span className="ttm-meta-line"><i className="fa fa-user" /> {item.Course}</span>
                          </div>
                          <p style={{ textOverflow: "ellipsis", overflow: 'hidden', maxHeight: "3.5rem", minHeight: "3.5rem" }}>{item.Description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
