import React, { Component } from "react";
import FacilitiesSidebar from "./FacilitiesSidebar";
import Header from "../layout/Header";
import Slider from "react-slick";
import image1 from "../../images/facility/clinicalFacility/image1.jpg";
import image2 from "../../images/facility/clinicalFacility/image2.jpg";
import image3 from "../../images/facility/clinicalFacility/image3.jpg";
import image4 from "../../images/facility/clinicalFacility/image4.jpg";
import image5 from "../../images/facility/clinicalFacility/image5.JPG";
import image6 from "../../images/facility/clinicalFacility/image6.JPG";
import image7 from "../../images/facility/clinicalFacility/image7.JPG";
import image8 from "../../images/facility/clinicalFacility/image8.JPG";
import image9 from "../../images/facility/clinicalFacility/image9.JPG";
import image10 from "../../images/facility/clinicalFacility/image10.JPG";
import image11 from "../../images/facility/clinicalFacility/image11.JPG";

export class Clinical extends Component {
  
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row facilities_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h2 className="title" style={{ color: "white" }}>Facilities</h2>
                    <div className="ttm-color-home">
                      <span><a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}><i className="ti ti-home" />&nbsp;&nbsp;Home</a></span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">KIN Facilities</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title text-left with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">Clinical <span>Facilities</span></h2>
                  </div>
                </div>
                <Slider className="slick_slider project-single" {...slick_slider} arrows={false} dots={true}>
                  <div className="featured-thumbnail"><img src={image1} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image2} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image3} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image4} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image5} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image6} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image7} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image8} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image9} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image10} alt="image" className="img-fluid" /></div>
                  <div className="featured-thumbnail"><img src={image11} alt="image" className="img-fluid" /></div>
                </Slider>
                <br />
                <p className="text-justify">
                  The KIN is attached to Kempegowda hospital which is
                  its own parent hospital situated within the campus.
                  Kempegowda hospital is a multi-specialty hospital with
                  state-of-the-art facilities which offers all round
                  training for our nursing students. Students also get
                  exposure to various specialty postings like KIDWADI,
                  NIMHANS, SPANDANA which provides them abundant
                  opportunities to become perfect in their skills
                  rendering comprehensive patient care.
                </p>
              </div>
              <div className="col-lg-3 widget-area">
                <FacilitiesSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Clinical;
