import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../../../layout/Header";
import firestore from "../../../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import ObstetricsFaculties from "./ObstetricsFaculties";
import ObstetricsResearch from "./ObstetricsResearch";
import image1 from "../../../../images/Department/Obstetrics/image1.jpg";
import image2 from "../../../../images/Department/Obstetrics/image2.jpg";
import image3 from "../../../../images/Department/Obstetrics/image3.jpg";
import NoData from "../../../NoData";

export class ObstetricsDept extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      HOD: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "ObstetricsandGynae");
    const Hodkey = "HOD";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      const allDynamicData = this.getDynamicData(Snapshot);
      this.dynamicData.HOD = allDynamicData[Hodkey];
    }
    const arr = [];
    this.setState({ HOD: arr });
  }
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row obstetrics_banner ">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Obstetrics and Gynaecological Nursing
                    </h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          href={process.env.PUBLIC_URL + "/Department"}
                        >
                          {" "}
                          Department
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="ttm-row aboutus-section clearfix bottomzero-padding-section">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title  with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title ">
                      {" "}
                      Department of Obstetrics and Gynaecological{" "}
                      <span>Nursing </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className=" aboutus-section clearfix zero-padding-section">
          <div className="sidebar ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-lg-12 content-area">
                  <div className="ttm-single-product-details product">
                    <div className="ttm-tabs tabs-for-single-products">
                      <Tabs>
                        <TabList>
                          <Tab>
                            <span style={{ padding: "20px" }}>About</span>
                          </Tab>
                          <Tab>
                            <span style={{ padding: "20px" }}>
                              Head Of Department
                            </span>
                          </Tab>
                          <Tab>
                            <span style={{ padding: "30px" }}>
                              Faculty and Staff
                            </span>
                          </Tab>
                          <Tab>
                            <span style={{ padding: "40px" }}>
                              {" "}
                              Research and Innovations
                            </span>
                          </Tab>
                        </TabList>
                        <div className="border_content-tab">
                          <TabPanel>
                            {/* content-inner */}
                            <div className="content-inner">
                              <h2></h2>
                              <p className="text-justify">
                                The department of obstetrical and gynaecological
                                nursing takes pride quality teaching, it aims to
                                ensure quality education to students, in order
                                to provide comprehensive and holistic nursing
                                care to the mother and new-born including women
                                with gynaecological problems. The course provide
                                with theoretical and practical elements to under
                                graduate and post graduate students. The
                                students undergoing training in this specialty
                                will be able to acquire overall skill to
                                function as midwives focusing evidence based
                                practice both in the hospital and community. The
                                department encourages students research in the
                                core areas of women’s health, high risk
                                pregnancy, neonatal care pertaining to women and
                                new-borns. We ensure efficient and effective
                                services to our student community and enable
                                them to function as educators, managers and
                                researchers in the field of maternity nursing.
                              </p>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="section-title  with-desc text-left clearfix">
                                    <div className="title-header">
                                      <h2 className="title ">Vision </h2>
                                    </div>
                                  </div>
                                  <p className="mb-35 res-991-mb-20">
                                    Actively strive to meet the excellence in
                                    education, practice and research related to
                                    health needs of the individual, family and
                                    community to strengthen and maximize the
                                    potential of nursing students in the field
                                    of evolving health care context.
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <div className="section-title  with-desc text-left clearfix">
                                    <div className="title-header">
                                      <h2 className="title ">Mission </h2>
                                    </div>
                                  </div>
                                  <p className="mb-35 res-991-mb-20">
                                    Provide comprehensive nursing care to women
                                    during reproductive periods and new-born. •
                                    Appreciate the legal and ethical issues
                                    pertaining to obstetrics and gynaecological
                                    nursing. • Incorporate evidence based
                                    nursing practice and identify the areas of
                                    research in the field of obstetrics and
                                    gynaecological nursing. • Demonstrate skill
                                    in new-born care. • Develop competency in
                                    teaching women and their families in various
                                    maternity set-ups.
                                  </p>
                                </div>
                              </div>
                              <div className="row multi-columns-row ttm-boxes-spacing-5px">
                                <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                                  <div className="featured-imagebox featured-imagebox-portfolio ">
                                    <div className="featured-thumbnail">
                                      <img
                                        className="img-fluid"
                                        src={image1}
                                        alt="image"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                                  <div className="featured-imagebox featured-imagebox-portfolio ">
                                    <div className="featured-thumbnail">
                                      <img
                                        className="img-fluid"
                                        src={image2}
                                        alt="image"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                                  <div className="featured-imagebox featured-imagebox-portfolio ">
                                    <div className="featured-thumbnail">
                                      <img
                                        className="img-fluid"
                                        src={image3}
                                        alt="image"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              {this.dynamicData.HOD === undefined ? (
                                <NoData />
                              ) : (
                                this.dynamicData.HOD.length &&
                                this.dynamicData.HOD.map(function (
                                  item,
                                  index
                                ) {
                                  return (
                                    <div className="row">
                                      <div className="col-lg-6 col-xl-6">
                                        <div className="ttm_single_image-wrapper mb-35">
                                          <img
                                            className="img-fluid"
                                            src={item.Image}
                                            title="single-img-twenty"
                                            alt="single-img-twenty"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-6">
                                        <div className="ttm-pf-single-detail-box mb-35">
                                          <div className="ttm-pf-single-title">
                                            <h5>{item.Name}</h5>
                                          </div>
                                          <ul className="ttm-pf-detailbox-list">
                                            <li>
                                              <i className="fa fa-user" />
                                              <span> Designation : </span>
                                              {item.Designation}{" "}
                                            </li>
                                            <li>
                                              <i className="far fa-bookmark" />
                                              <span> qualification :</span>
                                              {item.Qualification}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <ObstetricsFaculties />
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <ObstetricsResearch />
                            </div>
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ObstetricsDept;
